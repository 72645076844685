/*
* Hero Banner CSS
*/
.hc-hero-bg-img[data-v-e4827bdd] {
  background-image: url("../images/heroimage.png");
  background-size: cover;
  background-position: 100%;
  min-height: 300px;
  position: relative;
}
.hc-hero-btn[data-v-e4827bdd] {
  opacity: 0.99;
  border-radius: 6px;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: var(--color-white);
  transition: .5s ease;
  background-image: linear-gradient(106deg,var(--color-button-light) 6%,var(--color-button-dark) 97%,var(--color-button-dark) 97%);
  z-index: 10;
  padding: 19px 60px;
}
.hc-hero-btn[data-v-e4827bdd]:hover {
  background-color: #ffffff !important;
    color: #43b7a7 !important;
    background-image: none;
    border: 1px solid #43B7A6;
    border-radius: 6px;
}
.yt-recent-search-wrap[data-v-e4827bdd]{
  border-radius: 10px;
    box-shadow: 0 3px 40px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 32px 20px 27px;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 50;
}
.recent-search-list-wrap[data-v-e4827bdd]{
  text-align: left;
}
.search-no-sub-ttl[data-v-e4827bdd] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.hc-hero-banner[data-v-e4827bdd] {
  position: relative;
  justify-content: center;
}
.hc-hero-banner[data-v-e4827bdd]::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
}
.hero-content[data-v-e4827bdd]{
  position: relative;
  z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.button[data-v-e4827bdd] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-primary);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
@media only screen and (max-width: 992px) {
.heroimage[data-v-e4827bdd] {
    width: 100%;
}
.shopnow[data-v-e4827bdd] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -50px;
    width: 100px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}
}
@media only screen and (min-width: 992px) {
.heroimage[data-v-e4827bdd] {
    width: 100%;
}
.shopnow[data-v-e4827bdd] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -100px;
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}
}
.herocontainer[data-v-e4827bdd] {
  display: flex;
  justify-content: center;
}
.banner-title[data-v-e4827bdd]{
  font-size: 36px;
  color: var(--color-white);
  margin-top: 0;
  margin-bottom: 49px;
}
.banner-subtitle[data-v-e4827bdd]{
  font-size: 20px;
  color: var(--color-white);
  margin: 40px 0;
}
.hero[data-v-e4827bdd] {
  max-width: 1366px;
}
.hc-hero-banner[data-v-e4827bdd] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.location-icon[data-v-e4827bdd]{
  color: #8b8f95;
  height: auto;
  width: 70px;
  padding: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.search-icon[data-v-e4827bdd]{
  color: #8b8f95;
  height: 100%;
  width: 72px;
  padding: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.searchcontainer[data-v-e4827bdd]{
  position: relative;
}
.yt-main-header .recent-search-tag-name[data-v-e4827bdd] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.searchinput[data-v-e4827bdd]{
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  padding: 10px 15px;
  height: 80px;
  display: flex;
  align-items: center;
  min-width: 1000px;
  width: 100%;
  padding-left: 74px;
  line-height: 1.15;
  color: var(--color-black);
  font-size: 20px;
  padding-top: 18px;
  padding-bottom: 19px;
}
.searchinput[data-v-e4827bdd]:focus{
  outline: none;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.hc-hero-banner[data-v-e4827bdd] {
    min-height: 500px;
}
.searchinput[data-v-e4827bdd]{
   width: 600px;
   min-width: 0px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.hc-hero-btn[data-v-e4827bdd] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 164px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    bottom: 43px;
    margin-bottom: 15px !important;
}
.banner-title[data-v-e4827bdd]{
    margin-top: 15px;
    font-size: 25px;
}
.searchinput[data-v-e4827bdd]{
    height: 65px;
}
.hc-hero-banner[data-v-e4827bdd] {
    min-height: 200px;
}
.search-no-sub-ttl[data-v-e4827bdd] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
.searchinput[data-v-e4827bdd]{
    width: 300px;
}
.hc-hero-btn[data-v-e4827bdd]{
    padding: 0.600rem 0.90rem !important;
}
.yt-recent-search-wrap[data-v-e4827bdd] {
    background-color: #f6f8fa;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    margin: 0 !important;
}
.yt-recent-search-wrap > .d-flex[data-v-e4827bdd] {
    margin: 13px 18px !important;
}
.yt-main-header .recent-search-tag-name[data-v-e4827bdd] {
    font-size: 15px;
    line-height: 1.2;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
.hero-content[data-v-e4827bdd] {
    min-height: 500px;
}
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.hc-hero-btn[data-v-e4827bdd] {
    bottom: 95px;
}
.hc-hero-banner[data-v-e4827bdd] {
    min-height: 500px;
}
}
