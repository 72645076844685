.deals[data-v-a2b17bef] {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}
.products[data-v-a2b17bef] {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}
.more[data-v-a2b17bef] {
  float: right;
  font-size: 13px;
  cursor: pointer;
}
.one[data-v-a2b17bef] {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.two[data-v-a2b17bef] {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.three[data-v-a2b17bef] {
  width: 100%;
  height: 100%;
  margin-left: 0;
  float: left;
  margin-right: 0;
  padding-right: 0;
}
.fullscreen[data-v-a2b17bef] {
  width: 100%;
}
.oneintwo[data-v-a2b17bef] {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shopnowbutton[data-v-a2b17bef] {
  width: 140px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.savetext[data-v-a2b17bef] {
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.textblock1[data-v-a2b17bef] {
  margin-top: -150px;
  margin-left: 80px;
}
.welcometext[data-v-a2b17bef] {
  color: black;
  font-weight: 600;
  font-size: 40px;
}
.textblock2[data-v-a2b17bef] {
  margin-top: -350px;
  margin-left: 80px;
}
.headtop[data-v-a2b17bef] {
  color: white;
  font-weight: 200px;
}
.uptooff[data-v-a2b17bef] {
  color: white;
  font-weight: 600;
  font-size: 60px;
}
.textblock3[data-v-a2b17bef] {
  margin-top: -300px;
  margin-left: 350px;
}
.logohead1[data-v-a2b17bef] {
  margin-left: 30px;
  font-weight: 500px;
  margin-top: -60px;
  font-size: 20px;
  color: white;
}
.shopnowgoldenbutton[data-v-a2b17bef] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: -70px;
  float: right;
  margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-a2b17bef] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 20px;
}
.dontmiss[data-v-a2b17bef] {
  font-size: 30px;
}
.offthirty[data-v-a2b17bef] {
  font-size: 70px;
  font-weight: 600;
}
.textbox4[data-v-a2b17bef] {
  margin-left: 120px;
  margin-top: -500px;
}
.hurryup[data-v-a2b17bef] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.dailydeal[data-v-a2b17bef] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.lowprice[data-v-a2b17bef] {
  color: white;
}
.textbox5[data-v-a2b17bef] {
  margin-left: 50px;
  margin-top: -200px;
}
.happytimes[data-v-a2b17bef] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.flat[data-v-a2b17bef] {
  font-size: 40px;
  color: white;
  font-weight: 700px;
}
.textbox7[data-v-a2b17bef] {
  margin-top: -230px;
  margin-left: auto;
  margin-right: auto;
}
.stores[data-v-a2b17bef] {
  width: 30%;
  cursor: pointer;
  margin-top: 70px;
}
.playbuttons[data-v-a2b17bef] {
  margin-left: 190px;
  margin-right: auto;
}
.download[data-v-a2b17bef] {
  font-size: 40px;
  font-weight: 400;
  color: black;
  margin-left: 100px;
}
.imglast[data-v-a2b17bef] {
  width: 100%;
  height: 100%;
}
.prodcluster[data-v-a2b17bef] {
  margin-bottom: 10px;
  margin-top: 10px;
}
.filterpage[data-v-a2b17bef] {
  margin-bottom: 100px;
  margin-top: 30px;
}
.filterpanel[data-v-a2b17bef] {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}
.abayas[data-v-a2b17bef] {
  font-size: 20px;
  font-weight: 500;
}
.yt-shop-category-name[data-v-a2b17bef] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
}
.filter_abayas[data-v-a2b17bef] {
  margin-top: 35px;
}
.recommeded[data-v-a2b17bef] {
  float: right;
  margin-right: -23px;
}
.recomdrop[data-v-a2b17bef] {
  margin-right: 20px;
  margin-left: 10px;
}
.selectedopt[data-v-a2b17bef] {
  /* width: 90px; */
  /* height: 40px; */
  border-radius: 24px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  padding: 5px;
  margin-left: 5px;
  font-size: 13px;
}
.showingnum[data-v-a2b17bef] {
  float: right;
}
.yt-product-showing-total-item[data-v-a2b17bef] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-first[data-v-a2b17bef],
.yt-second[data-v-a2b17bef] {
  color: var(--color-black);
}
.yt-selected-filter-wrap[data-v-a2b17bef] {
  margin-top: 10px;
  margin-bottom: 10px;
}
.pageroute[data-v-a2b17bef] {
  color: gray;
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.17;
}
.currpage[data-v-a2b17bef] {
  color: black;
}
.filter_view[data-v-a2b17bef] {
  margin-bottom: 5px;
  color: grey;
}
.cat_name[data-v-a2b17bef] {
  margin-bottom: 10px;
  padding-left: 20px;
}
.cat_name_top[data-v-a2b17bef] {
  padding-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
}
.listing[data-v-a2b17bef] {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}
.checkbox_div[data-v-a2b17bef] {
  margin-right: 20px;
  padding-top: 5px;
}
.cat_item[data-v-a2b17bef] {
  color: gray;
  margin-top: 2px;
  margin-bottom: 2px;
}
.color_opt[data-v-a2b17bef] {
  height: 10px;
  width: 10px;
  background-color: pink;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.searchcontainer[data-v-a2b17bef] {
  display: flex;
  align-items: center;
  height: 100%;
}
.searchinput[data-v-a2b17bef] {
  border-radius: 6px;
  border: solid 1px #e6e6e6;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 90%;
  padding-left: 48px;
}
.searchicon[data-v-a2b17bef] {
  height: auto;
  width: 14px;
  position: absolute;
  left: 50px;
}
.cat_color[data-v-a2b17bef] {
  color: gray;
}
.slider[data-v-a2b17bef] {
  width: 90%;
  height: 20px;
  background: var(--color-primary);
  opacity: 0.7;
  transition: opacity 0.2s;
}
/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-a2b17bef] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.shopnowgoldenbutton1[data-v-a2b17bef] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.savetext[data-v-a2b17bef] {
    font-size: 15px;
}
.textblock1[data-v-a2b17bef] {
    margin-top: -90px;
    margin-left: 50px;
}
.welcometext[data-v-a2b17bef] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock2[data-v-a2b17bef] {
    margin-top: -200px;
    margin-left: 40px;
    width: 50%;
}
.headtop[data-v-a2b17bef] {
    font-weight: 200px;
}
.uptooff[data-v-a2b17bef] {
    color: white;
    font-weight: 600;
    font-size: 30px;
}
.textblock3[data-v-a2b17bef] {
    margin-top: -250px;
    margin-left: 200px;
    width: 50%;
}
.three[data-v-a2b17bef] {
    padding-bottom: 10px;
}
.logohead1[data-v-a2b17bef] {
    width: 100%;
}
.hurryup[data-v-a2b17bef] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-a2b17bef] {
    font-size: 15px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-a2b17bef] {
    color: white;
    font-size: 10px;
}
.textbox5[data-v-a2b17bef] {
    margin-left: 30px;
    margin-top: -160px;
    width: 50%;
}
.happytimes[data-v-a2b17bef] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-a2b17bef] {
    font-size: 25px;
    color: white;
    font-weight: 700px;
}
.stores[data-v-a2b17bef] {
    width: 30%;
    margin-top: 0px;
}
.playbuttons[data-v-a2b17bef] {
    margin-left: 120px;
    margin-right: auto;
}
.download[data-v-a2b17bef] {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 120px;
}
.textbox7[data-v-a2b17bef] {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
}
.imglast[data-v-a2b17bef] {
    width: 100%;
    height: 100%;
}
.dontmiss[data-v-a2b17bef] {
    font-size: 15px;
}
.offthirty[data-v-a2b17bef] {
    font-size: 25px;
    font-weight: 600;
}
.textbox4[data-v-a2b17bef] {
    margin-left: 40px;
    margin-top: -150px;
    width: 50%;
}
}
/* for mobile */
@media only screen and (max-width: 992px) {
.filtertop[data-v-a2b17bef] {
    margin-top: 60px;
}
.selectedprops[data-v-a2b17bef] {
    margin-bottom: 10px;
}
.selectedopt[data-v-a2b17bef] {
    margin-top: 5px;
}
}
.dropcustom[data-v-a2b17bef] {
  background-color: skyblue;
}
.removefilter[data-v-a2b17bef] {
  cursor: pointer;
}
.clearall[data-v-a2b17bef] {
  padding-top: 5px;
  margin-left: 5px;
  color: red;
  font-size: 13px;
  cursor: pointer;
}
.all-prod-sort-tag-name[data-v-a2b17bef] {
  line-height: 2.4em;
  font-size: 18px;
  color: var(--color-dark-grey);
}
.yt-recommend-wrapper[data-v-a2b17bef] {
  width: 100%;
  box-shadow: none;
  border: none;
  margin: 0;
  border-radius: 0;
  border: solid 1px #e6e6e6;
  border-top: none !important;
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-a2b17bef] {
  box-shadow: 0px -20px 20px 0 rgba(0, 0, 0, 0.05);
}
.yt-recommend-inner .show .yt-recommend-wrapper[data-v-a2b17bef] {
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/**/
.yt-close-icn[data-v-a2b17bef] {
  cursor: pointer;
  margin-left: 13px;
}
.yt-product-page-recommend-btn[data-v-a2b17bef] {
  border-radius: 10px !important;
  border: solid 1px#f0f0f0 !important;
  background-color: #ffffff !important;
  min-width: 228px;
  text-align: left;
  box-shadow: none !important;
  position: relative;
  color: var(--color-black);
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.11 !important;
  padding: 14px !important;
  transition: .5s ease;
}
.yt-product-page-recommend-btn[data-v-a2b17bef]:hover{
  color: black;
}
.yt-product-page-recommend-btn[data-v-a2b17bef]::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-black);
}
.current-active-filter[data-v-a2b17bef] {
  position: relative;
}
.current-active-filter[data-v-a2b17bef]::after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../images/verify-icn.png");
  display: block;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.yt-filter-selected .yt-flt-tag[data-v-a2b17bef] {
  
  border: solid 1px #e6e6e6;
  border-radius: 23px;
  background-color: #ffffff;
  padding: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-black);
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-a2b17bef] {
  background: #fff !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: inherit !important;
  border: solid 1px #e6e6e6 !important;
  border-bottom: none !important;
}
.yt-recommend-wrapper .dropdown-item[data-v-a2b17bef] {
  padding: 10px 14px;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
}
.yt-clear-all[data-v-a2b17bef] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #e65e52;
  transition: .5s ease;
}
.yt-clear-all[data-v-a2b17bef]:hover{
  color: black;
  text-decoration: underline;
}
.yt-shop-pg-cm-lft-col-pd[data-v-a2b17bef] {
  padding-right: 10px;
}
.yt-shop-pg-cm-rgt-col-pd[data-v-a2b17bef] {
  padding-left: 10px;
}
.yt-cm-lt-col[data-v-a2b17bef] {
  padding-right: 10px;
}
.yt-cm-rt-col[data-v-a2b17bef] {
  padding-left: 10px;
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-a2b17bef] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-a2b17bef] {
    padding-left: 20px;
}
.yt-shop-pg-cm-lft-col-pd[data-v-a2b17bef] {
    padding-right: 20px;
}
.yt-shop-pg-cm-rgt-col-pd[data-v-a2b17bef] {
    padding-left: 20px;
}
.yt-filter-selected .yt-flt-tag[data-v-a2b17bef],
  .yt-product-showing-total-item[data-v-a2b17bef] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-selected-filter-wrap[data-v-a2b17bef] {
    margin-top: 22px;
}
.yt-shop-category-name[data-v-a2b17bef] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-filter-selected .yt-flt-tag[data-v-a2b17bef] {
    line-height: 1.11;
    font-size: 18px;
}
}
@media (min-width: 1280px) {
.yt-sp-mb-recomment-wrap[data-v-a2b17bef],
  .yt-sp-mb-filter-wrapper[data-v-a2b17bef] {
    display: none;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-a2b17bef] {
    display: block;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 10;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 20px;
}
}
@media (min-width: 768px) {
.yt-mb-close-filter[data-v-a2b17bef] {
    display: none;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-filter-selected[data-v-a2b17bef] {
    display: none !important;
}
#SingelnewProducts[data-v-a2b17bef] {
    padding-top: 35px;
}
.all-prod-sort-tag-name[data-v-a2b17bef],
  .yt-product-page-recommend-btn span[data-v-a2b17bef],
  .yt-product-page-recommend-btn[data-v-a2b17bef]::after,
  .yt-cm-lt-col[data-v-a2b17bef] {
    display: none;
}
.yt-cm-rt-col[data-v-a2b17bef] {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    background-color: #fff;
    margin-top: 40px;
}
.yt-product-page-recommend-btn[data-v-a2b17bef] {
    background-color: transparent !important;
    border: none !important;
    min-width: auto;
}
.yt-product-page-recommend-btn[data-v-a2b17bef]:active,
  .yt-product-page-recommend-btn[data-v-a2b17bef]:focus,
  .yt-product-page-recommend-btn[data-v-a2b17bef]:hover {
    color: var(--color-dark-black) !important;
}
.yt-recommend-wrapper .dropdown-item[data-v-a2b17bef] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-product-page-recommend-btn .yt-recmnd-mb-txt[data-v-a2b17bef],
  .yt-sp-mb-filter-wrapper .yt-recmnd-mb-txt[data-v-a2b17bef] {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 5px;
}
.filter_abayas[data-v-a2b17bef] {
    margin-top: 0;
}
.filterpage .yt-cm-row.row[data-v-a2b17bef] {
    margin-right: -20px;
    margin-left: -20px;
    position: relative;
}
.filterpage .filtertop[data-v-a2b17bef] {
    margin-top: 0;
    position: absolute;
    top: -50px;
    left: 20px;
    right: 20px;
}
.yt-recommend-wrapper[data-v-a2b17bef] {
    left: auto !important;
    right: 0 !important;
    min-width: 200px;
    transform: translate3d(-0px, 48px, 0px) !important;
    border-radius: 10px;
    border: solid 1px var(--color-dark-black) !important;
    padding: 0;
    overflow: hidden;
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-a2b17bef] {
    background: transparent !important;
    border: none !important;
}
.filterpage[data-v-a2b17bef] {
    margin-top: 0;
}
.yt-shop-category-name[data-v-a2b17bef] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-sp-mb-filter-wrapper[data-v-a2b17bef] {
    cursor: pointer;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
#SingelnewProducts[data-v-a2b17bef] {
    padding-top: 25px;
}
.yt-recommend-wrapper .dropdown-item[data-v-a2b17bef] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-recommend-wrapper[data-v-a2b17bef] {
    min-width: 191px;
}
.yt-cm-lt-col.ytMbfilteropen[data-v-a2b17bef] {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    background-color: #fff;
}
.yt-cm-lt-col.ytMbfilteropen .yt-mb-close-filter[data-v-a2b17bef] {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 22px;
    z-index: 60;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-product-page-recommend-btn[data-v-a2b17bef] {
    font-size: 16px !important;
}
.yt-filter-selected .yt-flt-tag[data-v-a2b17bef] {
    font-size: 14px;
}
.yt-clear-all[data-v-a2b17bef] {
    font-size: 14px;
}
.yt-recommend-wrapper .dropdown-item[data-v-a2b17bef] {
    font-size: 16px;
}
.all-prod-sort-tag-name[data-v-a2b17bef] {
    font-size: 16px;
    line-height: 3;
}
}
