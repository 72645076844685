.co-title-bar .modal-title[data-v-3219dd8e] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.co-body-text[data-v-3219dd8e] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.co-title-bar span[data-v-3219dd8e] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.pp-co-btn-modal[data-v-3219dd8e] {
  width: 50%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-co-btn-modal[data-v-3219dd8e]:hover {
  border-color: #fff;
}
.pp-co-btn-light-grey[data-v-3219dd8e] {
  color: var(--color-dark-grey);
}
.pp-co-btn-dark-grey[data-v-3219dd8e] {
  color: var(--color-black);
}
.yt-form-spacer[data-v-3219dd8e] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.co-bottom-bar[data-v-3219dd8e] {
  position: relative;
  border-top: 2px solid #dbdbdb;
}
