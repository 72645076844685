.radius-10[data-v-e71b610c] {
  border-radius: 10px;
}
.trans-fl-pg-mb-30[data-v-e71b610c] {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80[data-v-e71b610c] {
  margin-bottom: 80px;
}
.trans-fl-ttl[data-v-e71b610c] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-orange);
}
.trans-fl-text[data-v-e71b610c] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.trans-fl-btn[data-v-e71b610c] {
  opacity: 0.99;
  background-image: linear-gradient(100deg, #81cabf 6%, #29af9d 97%, #29af9d 97%);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  border: none;
  transition: .5s ease;
}
.trans-fl-btn[data-v-e71b610c]:hover{
  background-color: #ffffff;
  color: #29af9d;
  border: 1px solid #29af9d;
  background-image: none;
}
.trans-fl-cancel-btn[data-v-e71b610c] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
  box-shadow: none !important;
}
.yt-transaction-cl-icn[data-v-e71b610c] {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap[data-v-e71b610c] {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1280px) {
.trans-fl-pg-inner-wrap[data-v-e71b610c] {
    margin-top: 300px !important;
    margin-bottom: 300px;
}
}
@media (min-width: 1900px) {
.trans-fl-pg-inner-wrap[data-v-e71b610c] {
    margin-top: 350px !important;
    margin-bottom: 350px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.trans-fl-pg-inner-wrap[data-v-e71b610c] {
    margin-top: 200px !important;
    margin-bottom: 200px;
}
.trans-fl-wrap[data-v-e71b610c] {
    margin-top: 75px;
    margin-bottom: 40px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.trans-fl-pg-inner-wrap[data-v-e71b610c] {
    margin-top: 150px !important;
    margin-bottom: 150px;
    padding: 11px 20px 20px !important;
}
.trans-fl-btn[data-v-e71b610c] {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
}
.cart-pg-empty-main-wrap[data-v-e71b610c] {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.trans-fl-cancel-btn[data-v-e71b610c] {
    font-size: 12px;
    line-height: 1.17;
}
.trans-fl-ttl[data-v-e71b610c] {
    font-size: 16px;
    line-height: 1.13;
}
.trans-fl-text[data-v-e71b610c] {
    font-size: 14px;
    line-height: 1.14;
}
.trans-fl-wrap[data-v-e71b610c] {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
}
}
