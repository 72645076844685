.yt-main-header .topbar[data-v-562160be] {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}
.yt-main-header .logocontainer[data-v-562160be] {
  background-color: white;
  height: 125px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
}

/* .yt-main-header .logobox {
  background-color: var(--color-dark-black);
} */
.yt-header-top-message[data-v-562160be] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}
.yt-main-header .supportbox[data-v-562160be] {
  height: 95px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .support[data-v-562160be] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .supportimage[data-v-562160be] {
  height: auto;
  width: 38.5px;
  margin-right: 10px;
}
.yt-main-header .supportnumber[data-v-562160be] {
  display: flex;
  flex-direction: column;
}
.yt-main-header .supportnumber .title[data-v-562160be] {
  font-size: 15px;
  line-height: 1.2;
  font-weight: normal;
  color: var(--color-dark-grey);
  padding-bottom: 5px;
  margin-top: -5px;
}
.yt-main-header .supportnumber .number[data-v-562160be] {
  font-weight: 500;
  line-height: 1.15;
  font-size: 20px;
  color: var(--color-black);
}
.yt-main-header .searchcontainer[data-v-562160be] {
  display: flex;
  align-items: center;
  height: 100%;
}
.yt-main-header .searchinput[data-v-562160be] {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  padding: 10px 15px;
  height: 45px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 74px;
  line-height: 1.15;
  color: var(--color-black);
  font-size: 20px;
  font-weight: 500;
  padding-top: 18px;
  padding-bottom: 19px;
}
.yt-main-header[data-v-562160be] ::-webkit-input-placeholder {
  color: #8f9398cc;
}
.yt-main-header .searchicon[data-v-562160be] {
  height: auto;
  width: 65px;
  padding: 20px;
  position: absolute;
  left: 18px;
}
.yt-main-header .cartcontainer[data-v-562160be] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.yt-main-header .carticon[data-v-562160be] {
  height: auto;
  width: 26px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 10px;
  color: #596271;
}
.yt-main-header .favoriteicon[data-v-562160be] {
  height: auto;
  width: 26px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 10px;
  color: #596271;
}
.yt-main-header .loginbutton[data-v-562160be] {
  width: 180px;
  height: 50px;
  border-radius: 6px;
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-image: linear-gradient(
    106deg,
    var(--color-button-light) 6%,
    var(--color-button-dark) 97%,
    var(--color-button-dark) 97%
  );
}
.loginbutton[data-v-562160be]:hover {
  background-color: #ffffff !important;
  color: #43b7a7 !important;
  background-image: none;
  border: 1px solid #43B7A6;
  border-radius: 6px;
}
.yt-main-header .menucontainer[data-v-562160be] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
  background-color: white;
}
.yt-main-header .menuicon[data-v-562160be] {
  height: auto;
  width: 16px;
  position: absolute;
  left: 20px;
  background-color: white;
}
.yt-main-header nav.navbar[data-v-562160be] {
  padding: 0px;
}
.yt-main-header ul.navbar-nav[data-v-562160be] {
  width: 100%;
  justify-content: space-around;
  background-color: white;
}
.yt-main-header .nav-link[data-v-562160be] {
  color: var(--color-black) !important;
}
.yt-main-header .nav-link[data-v-562160be]:hover{
  color: var(--color-blue) !important;
}
.yt-main-header .nav-link.active[data-v-562160be] {
  color: var(--color-blue) !important;
  text-decoration: underline;
  font-weight: bold;
  text-underline-position: under;
}

/* .yt-main-header .cm-drop-down-wrap {
  position: static;
} */
.yt-main-header .cm-big-drop-menu[data-v-562160be] {
  width: 100%;
  padding: 0;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
}
.yt-main-header .cm-big-menu-head .dropdown-divider[data-v-562160be] {
  border-top: 1px solid var(--color-dark-grey);
}
.yt-main-header .cm-big-menu-inner .dropdown-item[data-v-562160be] {
  font-size: 17px;
  line-height: 1.4;
  text-align: left;
  color: #3e454f;
  padding: 12px 15px;
  /* margin-bottom: 5px; */
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-562160be] {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-main-header .head-dropdown-arrow-icn[data-v-562160be] {
  font-size: 12px;
  margin-left: 4px;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show > a[data-v-562160be] {
  color: #3e454f !important;
  font-weight: 600;
  /* border-bottom: 2px solid var(--color-blue); */
}
.yt-main-header li.cm-drop-down-wrap.dropdown .nav-link[data-v-562160be] {
  padding: 29px 0px;
  position: relative;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show .nav-link[data-v-562160be]:after {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 5px;
  background-color: var(--color-blue);
  content: "";
}
.yt-main-header .header-search-field-wrap[data-v-562160be] {
  position: relative;
}
.yt-main-header .yt-recent-search-wrap[data-v-562160be] {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 50;
}
.yt-main-header .recent-search-tag-name[data-v-562160be] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-main-header .recent-search-list-wrap .active[data-v-562160be] {
  background: transparent;
  color: inherit;
}
.yt-main-header .yt-recent-search-wrap[data-v-562160be] {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
}
.yt-main-header .yt-no-search-found-wrap[data-v-562160be] {
  padding: 54px 30px 46px;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-562160be] {
  border-bottom: 2px solid #e6e6e6;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-562160be]:first-child {
  padding-top: 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-562160be]:last-child {
  padding-bottom: 0 !important;
}
.yt-main-header .search-no-ttl[data-v-562160be] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}
.yt-main-header .search-no-sub-ttl[data-v-562160be] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.yt-main-header .yt-main-menu .nav-item[data-v-562160be] {
  cursor: pointer;
}
.yt-main-header .logoimage[data-v-562160be] {
  width: 100%;
  max-width: 194px;
  margin-left: 0px;
  margin-top: 18.7px;
}
.yt-head-login-btn[data-v-562160be] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}
.user_Name[data-v-562160be] {
  cursor: pointer;
}
.yt-mobile-side-nav-wrap[data-v-562160be] {
  display: none;
}
.yt-main-header .cartcontainer span.w3-green[data-v-562160be] {
  text-align: center;
  padding: 5px 6px !important;
  border-radius: 50px !important;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  min-width: 20px !important;
  display: inline-block;
}
@media (min-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-562160be] {
    max-width: 1421px;
}
.yt-main-header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item[data-v-562160be] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-562160be] {
    font-size: 28px;
    line-height: 1.14;
}
.yt-main-header .searchinput[data-v-562160be] {
    padding-top: 24px;
    padding-bottom: 23px;
}
.yt-main-header .logoimage[data-v-562160be] {
    max-width: 194px;
}
}
@media only screen and (min-width: 992px) {
.yt-main-header .order-sm-1[data-v-562160be] {
    order: 0;
}
.yt-main-header .searchicon[data-v-562160be] {
    left: 30px;
}
.yt-main-header ul.navbar-nav[data-v-562160be] {
    padding: 0px 0px 0px 149px;
}
}
@media only screen and (min-width: 768px) {
.yt-only-mobile-vw[data-v-562160be] {
    display: none;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-562160be] {
    display: none;
}
.yt-main-header .logoimage[data-v-562160be] {
    width: 100%;
    max-width: 250px;
    margin-left: 15px;
    margin-top: 30px;
}
}
@media only screen and (max-width: 992px) {
.yt-main-header .order-sm-1[data-v-562160be] {
    order: 1;
}
.yt-main-header .searchicon[data-v-562160be] {
    left: 32px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.userbox span.uname[data-v-562160be],
  .userbox svg[data-v-562160be] {
    display: none;
}
.menucontainer[data-v-562160be] {
    display: none !important;
}
.yt-cart-icn-mainwp[data-v-562160be] {
    position: relative;
    margin-right: 15px;
}
.yt-cart-icn-mainwp span.w3-green[data-v-562160be] {
    position: absolute !important;
    right: 0 !important;
}
.yt-main-header .logocontainer .yt-head-col[data-v-562160be] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-main-header .yt-head-hide-srch-col[data-v-562160be],
  .yt-main-header .topbar[data-v-562160be] {
    display: none;
}
.yt-main-header .logocontainer[data-v-562160be] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.yt-main-header .supportbox[data-v-562160be] {
    display: none;
}
.yt-main-header .logoimage[data-v-562160be] {
    max-width: 50px;
    margin-left: 20px;
    margin-top: 5px;
}
.yt-main-header .logobox[data-v-562160be] {
    margin-left: 38px;
}
.yt-main-header .loginbutton span[data-v-562160be] {
    display: none;
}
.yt-main-header .loginbutton span.yt-head-lgn-btn[data-v-562160be] {
    display: block !important;
}
.yt-main-header .loginbutton span[data-v-562160be] {
    font-size: 18px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    color: var(--color-dark-black);
}
.yt-main-header .loginbutton[data-v-562160be] {
    background: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
}
.yt-main-header .carticon[data-v-562160be] {
    margin-right: 11px;
    width: 21px;
}
.yt-main-header .favoriteicon[data-v-562160be] {
    margin-right: 11px;
    width: 21px;
}
.yt-main-header .yt-head-seachicn-mb[data-v-562160be] {
    display: block !important;
    position: inherit;
    margin-right: 11px;
}
.yt-head-mb-search-panel[data-v-562160be] {
    display: block !important;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-562160be] {
    display: block;
}
.yt-mobile-side-nav-wrap[data-v-562160be] {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.yt-mobile-side-inner-content[data-v-562160be] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.yt-mb-user-profile[data-v-562160be] {
    padding: 20px;
    background-color: var(--color-dark-black);
    color: #fff;
}
.yt-mobile-side-inner-content .yt-inner-cnt-logo[data-v-562160be] {
    padding: 15px 20px 20px;
}
  /* .yt-mobile-side-inner-content .logobox-mb img {
    background-color: var(--color-dark-black);
  } */
.yt-mobile-nav-content[data-v-562160be] {
    padding: 17px 20px;
    border-bottom: solid 1px #dbdbdb;
}
.yt-mobile-nav-content[data-v-562160be]:last-child {
    border: none;
}
.yt-mb-user-profile .yt-head-name[data-v-562160be] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-mb-user-profile .yt-head-email[data-v-562160be] {
    font-size: 8px;
    line-height: 1.13;
}
.yt-mobile-nav-content li:last-child a[data-v-562160be] {
    padding-bottom: 0;
}
.yt-mb-user-prfl-lks li:first-child a[data-v-562160be] {
    padding-top: 0;
}
.yt-mb-user-prfl-lks[data-v-562160be] {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
}
.yt-main-header .yt-mobile-nav-content .cm-big-drop-menu[data-v-562160be] {
    padding: 0;
    border: none;
}
.yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a[data-v-562160be] {
    border-bottom: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider[data-v-562160be] {
    border-bottom: solid 1px #dbdbdb;
    border-top: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item[data-v-562160be] {
    font-size: 10px;
    line-height: 1.1;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item[data-v-562160be] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-black);
}
.yt-mb-user-profile .yt-mb-nav-login-btn[data-v-562160be] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
}
.yt-mb-innet-search[data-v-562160be] {
    padding: 14px 15px 0;
}
.yt-main-header .yt-mb-innet-search .searchicon[data-v-562160be] {
    top: 50%;
    transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-562160be] {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-562160be]::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-562160be]::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-562160be]:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-562160be]:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
    line-height: 1.17;
}
.yt-mb-header-search-bar-wrap input[data-v-562160be] {
    border: none !important;
    padding: 12px 0;
    outline: none !important;
    width: 100%;
    font-size: 18px;
    font-weight: 500;

    line-height: 1.11;
}
.yt-main-header .yt-recent-search-wrap[data-v-562160be] {
    background-color: #f6f8fa;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    margin: 0 !important;
}
.yt-main-header .yt-recent-search-wrap > .d-flex[data-v-562160be] {
    margin: 13px 18px !important;
}
.yt-main-header .yt-mb-header-search-bar-wrap[data-v-562160be] {
    padding: 17px 20px;
    background: #fff;
}
.yt-main-header .recent-search-list-wrap[data-v-562160be] {
    padding: 0 20px;
    background: #fff;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-562160be],
  .yt-main-header .recent-search-list-wrap .list-group-item[data-v-562160be]:first-child,
  .yt-main-header .recent-search-list-wrap .list-group-item[data-v-562160be]:last-child {
    padding: 15px 0 !important;
    font-size: 15px;
    line-height: 1.2;
}
.yt-main-header .recent-search-tag-name[data-v-562160be] {
    font-size: 15px;
    line-height: 1.2;
}
.yt-header-search-close-icn[data-v-562160be] {
    position: absolute;
    top: 12px;
    right: 23px;
    display: none;
}
.yt-header-search-close-icn.d-none[data-v-562160be] {
    display: block !important;
    max-width: 20px;
}
.yt-mb-header-search-bar-wrap input[data-v-562160be]::-webkit-input-placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
}
.yt-mb-header-search-bar-wrap input[data-v-562160be]:-ms-input-placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
}
.yt-mb-header-search-bar-wrap input[data-v-562160be]::placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
}
.yt-mb-header-search-bar-wrap input[data-v-562160be]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #dbdbdb;
}
.yt-mb-header-search-bar-wrap input[data-v-562160be]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #dbdbdb;
}
.yt-no-srch-found[data-v-562160be] {
    margin-top: 58px;
}
.yt-main-header .search-no-ttl[data-v-562160be] {
    font-size: 17px;
    line-height: 1.12;
    margin-top: 46px;
    margin-bottom: 8px;
}
.yt-main-header .search-no-sub-ttl[data-v-562160be] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
}
.usericon[data-v-562160be] {
  width: 42px;
  height: 40px;
  border-radius: 50%;
}
.uname[data-v-562160be] {
  margin-left: 10px;
  color: gray;
}
.userbox[data-v-562160be] {
  cursor: pointer;
}
@media (min-width: 1366px) and (max-width: 1900px) {
  /* .yt-main-header .cm-big-drop-menu {
    padding: 40px;
  } */
.yt-main-header .supportnumber .title[data-v-562160be] {
    font-size: 14px;
}
.yt-main-header .supportnumber .number[data-v-562160be] {
    font-size: 18px;
}
.yt-main-header .searchinput[data-v-562160be] {
    font-size: 16px;
}
.yt-main-header .loginbutton[data-v-562160be] {
    font-size: 18px;
}
.yt-main-header .yt-main-menu .nav-item[data-v-562160be] {
    font-size: 20px;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-562160be] {
    font-size: 20px;
}
}
