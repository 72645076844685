/*
* YT Product Slider CSS
*/
.yt-product-fav[data-v-914885ea] {
  width: 24px;
}
.yt-product-off[data-v-914885ea] {
  background-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-product-slider-cpnt[data-v-914885ea] {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 50px;
}
.yt-component-wrapper[data-v-914885ea] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-914885ea] {
  outline: none !important;
  box-shadow: none;
}
.yt-slider-left[data-v-914885ea],
.yt-slider-right[data-v-914885ea] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  cursor: pointer;
}
.yt-item-slider[data-v-914885ea] {
  position: relative;
  width: 100%;
  margin: 0px 10px;
}
.yt-product-add-btn.buttoncart[data-v-914885ea] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: var(--color-dark-black);
}
.yt-product-title[data-v-914885ea] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yt-component-more[data-v-914885ea] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-comonent-top-title[data-v-914885ea] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-product-slider-img[data-v-914885ea] {
  max-height: 150px;
}
.yt-product-sale-price[data-v-914885ea] {
  color: var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-reg-price2[data-v-914885ea] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-add-btn[data-v-914885ea] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
}
@media (min-width: 768px) {
.yt-slider-left[data-v-914885ea] {
    left: -40px;
}
.yt-slider-right[data-v-914885ea] {
    right: -40px;
}
}
@media (max-width: 768px) {
.yt-slider-left[data-v-914885ea] {
    left: 5px !important;
}
.yt-slider-right[data-v-914885ea] {
    right: 5px !important;
}
}
