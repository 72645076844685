.topbar[data-v-9b178789] {

    height:20px;
    color: var(--color-black);
    background-color: #f6f8fa;
    font-size: 14px;
    margin:6px 0px;
    /*padding-left: 50px;*/
}
.logocontainer[data-v-9b178789] {
    background-color: white;
}
.logobox[data-v-9b178789] {
    background-color:var(--color-dark-black);
}
.logoimage[data-v-9b178789] {
    height:95px;
    width: 190px;
}
.supportbox[data-v-9b178789] {
    height:95px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.support[data-v-9b178789] {
    display: flex;
    justify-content: center;
    align-items: center;
}
.supportimage[data-v-9b178789] {
    height:auto;
    width: 26px;
    margin-right: 10px;
}
.supportnumber[data-v-9b178789] {
    display: flex;
    flex-direction: column;
}
.supportnumber .title[data-v-9b178789]{
    font-size: 12px;
    font-weight: normal;
    color: var(--color-dark-grey);
    padding-bottom: 5px;
    margin-top: -5px;
}
.supportnumber .number[data-v-9b178789]{
    font-weight: 500;
    font-size: 14px;
    color: var(--color-black);
}
.searchcontainer[data-v-9b178789]{
    display: flex;
    align-items: center;
    height:100%;
}
.searchinput[data-v-9b178789]{  
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 48px;
}
.searchicon[data-v-9b178789]{
    height: auto;
    width: 14px;
    position: absolute;
    left: 18px;
}
.cartcontainer[data-v-9b178789]{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.carticon[data-v-9b178789]{
    height: auto;
    width: 26px;
    margin-right: 20px;
    cursor: pointer;
}
.loginbutton[data-v-9b178789]{
    width: 140px;
    height: 36px;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
.menucontainer[data-v-9b178789]{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 2px;
    background-color: white;
}
.menuicon[data-v-9b178789] {
    height: auto;
    width: 16px;
    position: absolute;
    right: 32px;
    background-color: white;
}
nav.navbar[data-v-9b178789]{
    padding:0px;
}
ul.navbar-nav[data-v-9b178789]{
    width:100%;
    justify-content: space-around;
    background-color: white;
}
.nav-link[data-v-9b178789]{
    color:var(--color-black) !important;
}
.nav-link.active[data-v-9b178789] {
    color:var(--color-blue) !important;
    text-decoration: underline;
    font-weight: bold;
    text-underline-position: under;
}
@media only screen and (max-width: 992px) {
.order-sm-1[data-v-9b178789]{
        order:1;
}
.searchicon[data-v-9b178789]{
        left:32px;
}
}
@media only screen and (min-width: 992px) {
.order-sm-1[data-v-9b178789]{
        order:0;
}
.searchicon[data-v-9b178789]{
        left:18px;
}
ul.navbar-nav[data-v-9b178789]{
        padding:6px 226px;
}
}
.usericon[data-v-9b178789]{
    width: 42px;
    height: 40px;
}
.uname[data-v-9b178789]{
    margin-left: 5px;
    color: gray;
}
.userbox[data-v-9b178789]{
    cursor: pointer;
}

