
#pagination > .pagination {
  justify-content: center;
      background-color: #ffffff;
      padding: 15px 0px;
      color: #054995;
      border: 1px solid #054995;
      transition: .5s ease;
      border-radius: 15px;
      border-radius: 30px;
  }
 #pagination > .pagination > li{
    padding: 0px 15px;
  }
  #pagination > ul.pagination {
    margin-bottom: 0px;
  }
  #pagination > .pagination > .active{
    border-radius: 12px;
    padding: 0px 15px;
    color: var(--color-white);
    background-image: linear-gradient(to bottom, #29af9d 0%, #81cabf 79%);
}
#pagination > .pagination > .active >a{
outline: none;
}