.radius-10[data-v-d7389829] {
  border-radius: 10px;
}
.list-style-none[data-v-d7389829] {
  list-style: none;
}
.border-top-grey[data-v-d7389829] {
  border-top: 2px solid #dbdbdb;
}
.sp-current[data-v-d7389829] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.sp-image-vert-slider[data-v-d7389829] {
  position: relative;
  max-height: 550px;
}
.yt-product-img-inner-row .sp-inner-content-wrap.sp-spp-inner-image-wrap[data-v-d7389829] {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: 450px;
}
.yt-slider-down[data-v-d7389829],
.yt-slider-up[data-v-d7389829] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  color: #919191;
}
.yt-slider-up[data-v-d7389829] {
  top: 0;
}
.yt-slider-down[data-v-d7389829] {
  bottom: 0;
}
.rec[data-v-d7389829] {
  background: red;
}
div[data-v-d7389829] {
  box-shadow: none;
  outline: none !important;
}
.sp-inner-wrap[data-v-d7389829] {
  padding: 40px 30px 30px;
}
.yt-product-img-inner-row
  .sp-inner-content-wrap.sp-spp-inner-image-wrap
  .sp-spimg-item[data-v-d7389829] {
  min-height: 450px;
  object-fit: cover;
  object-position: center;
  padding: 20px;
}
@media (min-width: 1900px) {
.sp-inner-wrap[data-v-d7389829] {
    padding: 40px 40px 30px;
}
}
@media (min-width: 1280px) {
.sp-image-vert-slider[data-v-d7389829] {
    overflow: hidden;
}
}
@media (min-width: 768px) {
.vert-slider-item img[data-v-d7389829] {
    height: 110px;
    width: 110px;
    object-fit: cover;
}
.vert-slider-item[data-v-d7389829] {
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    height: 133px;
    width: 133px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-inner-col[data-v-d7389829] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-slider-down[data-v-d7389829],
  .yt-slider-up[data-v-d7389829] {
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    z-index: 50;
}
.yt-slider-up[data-v-d7389829] {
    left: -10px;
}
.yt-slider-down[data-v-d7389829] {
    right: -10px;
    left: auto;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.vert-slider-item[data-v-d7389829] {
    height: 70px;
    width: 70px;
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
}
.vert-slider-item img[data-v-d7389829] {
    height: 57px;
    width: 57px;
    object-fit: cover;
}
}
