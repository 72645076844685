.radius-10[data-v-2a381d02] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-2a381d02] {
  margin-bottom: 30px;
}
.yt-currency-wrap[data-v-2a381d02]{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cart-pg-breadcrumbs[data-v-2a381d02] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}
.cartno-wrap[data-v-2a381d02] {
  margin-top: 52px;
  margin-bottom: 40px;
}
.myindex[data-v-2a381d02]{
  border-right: 1px solid #29af9d;
  margin-right: 10px;
  padding-right: 10px;
}
.myBlock[data-v-2a381d02]{
  padding: 15px 15px 15px 15px;
}
.upload-text[data-v-2a381d02]{
  margin: 12px 0 16px 10.9px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #3e454f;
    font-size: 20px;
}
.my-upload-text[data-v-2a381d02]{
  font-weight: 500;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #3e454f;
    font-size: 20px;
}
.prescription-file-image[data-v-2a381d02]{
  width: 25.3px;
    height: 33.6px;
}
.prescription-file-upload[data-v-2a381d02]{
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 20px;
}
.add-more-button[data-v-2a381d02]{
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 1.36px;
  color: var(--color-white);
  width: 100%;
  max-width: 130px;
  text-align: center;
  background-image: linear-gradient( 
106deg
, var(--color-button-light) 6%, var(--color-button-dark) 97%, var(--color-button-dark) 97% );
  transition: .5s ease;
  border: 1px solid #29af9d;
  padding: 10px 0px;
}
.add-more-button[data-v-2a381d02]:hover{
  background-image: none;
  color: #29af9d;
  border: 1px solid #29af9d;
  background-color: #fff;
}
.upload-block[data-v-2a381d02]{
  border-radius: 10px;
}
.prescription-proceed[data-v-2a381d02]{
  width: 180px;
  height: 50px;
  margin: 30.2px 0px 80px 680px;
  font-size: 18px;
  background-image: linear-gradient(106deg, #81cabf 6%, #29af9d 97%, #29af9d 97%);
  border: none;
}
.img_preview[data-v-2a381d02]{
  max-width: 100px;
}
.table-content-text[data-v-2a381d02]{
  margin: 26px 236px 14px 0px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #3e454f;
}
.removeCircle[data-v-2a381d02]{
  position: absolute;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  background-color: grey;
  border-radius: 10px;
}
.prescription-images[data-v-2a381d02]{
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 20px;
}
.prescription-proceed[data-v-2a381d02]:hover {
  background-image: none;
  border: 1px solid #29af9d;
  color: #29af9d;
  background-color: #fff;
}
.prescription-title[data-v-2a381d02]{
  width: 222px;
  height: 34px;
  margin: 0px 0px 31px 0px;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #3e454f;
}
.cart-pg-current[data-v-2a381d02] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.empty-cartn-ttl[data-v-2a381d02] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}
.empty-cart-text[data-v-2a381d02] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-2a381d02] {
  opacity: 0.99;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #fff;
  background-image: linear-gradient(104deg, #81cabf 6%, #29af9d 97%, #29af9d 97%);
  transition: .5s ease;
}
.empty-cart-btn[data-v-2a381d02]:hover {
  background-color: #fff;
  color: #29af9d;
  border: 1px solid #29af9d;
  background-image: none;
}
.cart-page-main-title[data-v-2a381d02] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}
.cart-product-image[data-v-2a381d02] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}
.cart-product-title[data-v-2a381d02] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 20px;
  max-width: 310px;
}
.cart-prodict-type th[data-v-2a381d02] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.cart-prodict-type th[data-v-2a381d02],
.cart-prodict-type td[data-v-2a381d02] {
  padding: 3px;
}
.cart-product-price[data-v-2a381d02] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: #43b7a7  ;
}
.cart-sub-total[data-v-2a381d02]{
  font-size: 18px;
  color: var(--color-black);
}
.cart-product-delete svg[data-v-2a381d02] {
  font-size: 22px;
  color: var(--color-black);
  cursor: pointer;
}
.item-quantity[data-v-2a381d02]{
  margin-left: 5px;
    font-weight: 500;
}
.cart-prodict-amount th[data-v-2a381d02] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}
.cart-product-amount-ttl[data-v-2a381d02] {
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-dark-grey);
}
.cart-product-amount-price[data-v-2a381d02] {
  font-size: 18px;
  line-height: 1.11;
  text-align: right;
  color: var(--color-black);
  display: block;
}
.cart-prodict-amount thead th[data-v-2a381d02] {
  padding-top: 0;
  padding-bottom: 18px;
  padding-left: 0;
  padding-right: 0;
}
.cart-prodict-amount tbody td[data-v-2a381d02],
.cart-prodict-total-amount tbody td[data-v-2a381d02],
.ccart-prodict-sub-total-amount tbody td[data-v-2a381d02],
.cart-prodict-sub-total-amount tbody td[data-v-2a381d02] {
  padding: 10px 0;
}
.cart-divider[data-v-2a381d02] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: 15px 0 20px;
}
.cart-divider-internal[data-v-2a381d02]{
  border-bottom: solid 1px  #dbdbdb !important;
  display: block;
  margin: 15px 0 20px;
}
.card-sub-text[data-v-2a381d02]{
  float: right;
  font-size: 35px;
  color: var(--color-black);
  text-align: center;
}
.cart-coupon .form-group[data-v-2a381d02] {
  position: relative;
}
.cart-coupon .form-control[data-v-2a381d02] {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}
.cart-coupon-btn[data-v-2a381d02] {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-orange);
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: var(--color-orange);
}
.cart-proceed-to-checkput[data-v-2a381d02] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #fff;
  opacity: 0.99;
  background-image: linear-gradient(106deg, #81cabf 6%, #29af9d 97%, #29af9d 97%);
}
.cart-proceed-to-checkput[data-v-2a381d02]:hover {
  background-color: #ffffff;
  color: #29af9d;
  border: 1px solid #29af9d;
  background-image: none;
}
.filter-blur-img[data-v-2a381d02]{
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
.mynote[data-v-2a381d02]{
  font-size: 20px;
}
.cart-checkout-tile[data-v-2a381d02] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}
.trash-icon[data-v-2a381d02]{
  color: var(--color-black);
  cursor: pointer;
  font-size: 30px;
}
.trash-icon[data-v-2a381d02]:hover{
  color: #e65e52  ;
}
.cart-select-aar-btn[data-v-2a381d02] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-blue);
}
.checkout-form-wrap .form-group .form-control[data-v-2a381d02] {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}
.checkout-form-label[data-v-2a381d02] {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-dark-grey);
}
.checkout-checkbox .form-group[data-v-2a381d02],
.yt-chekout-radio .form-group[data-v-2a381d02] {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}
.yt-empty-cart-wrap[data-v-2a381d02] {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input[data-v-2a381d02] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}
.cart-pg-inner-wrap .yt-checkout-form-chk-box[data-v-2a381d02],
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-2a381d02] {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}
.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box[data-v-2a381d02]:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-2a381d02]:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-2a381d02]:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}
.yt-checkout-chekbox-label[data-v-2a381d02] {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}
.cart-pg-inner-wrap
  .checkout-checkbox
  .form-check-input:checked
  + label[data-v-2a381d02]:before {
  background-color: var(--color-blue);
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked + label[data-v-2a381d02]:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label[data-v-2a381d02]:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: var(--color-blue);
  border-radius: 25px;
  left: 4px;
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label[data-v-2a381d02]:before {
  border-color: var(--color-blue);
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input[data-v-2a381d02] {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}
.checkout-form-wrap .form-check-label[data-v-2a381d02] {
  line-height: 1.5;
}
.cart-coupon .error .cart-coupon-code-message[data-v-2a381d02] {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}
.cart-coupon .success .cart-coupon-code-message[data-v-2a381d02] {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}
.cart-coupon-change-btn[data-v-2a381d02] {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}
.cart-coupon .cart-coupon-bottom-wrapper[data-v-2a381d02] {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}
.cart-coupon .error .cart-coupon-bottom-wrapper[data-v-2a381d02],
.cart-coupon .success .cart-coupon-bottom-wrapper[data-v-2a381d02] {
  opacity: 1;
}
form.yt-cart-disct-wrap .yt-form-cpn-err.form-group[data-v-2a381d02],
form.yt-cart-disct-wrap .success.form-group[data-v-2a381d02] {
  margin-bottom: 20px !important;
}
.cart-coupon .success .cart-coupon-bottom-wrapper .error-message[data-v-2a381d02],
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message[data-v-2a381d02],
.login-on-cart-page .lp-bottom-links[data-v-2a381d02] {
  display: none;
}
.yt-login-wrap[data-v-2a381d02] {
  margin-top: 0 !important;
}
.cart-prodict-amount thead th[data-v-2a381d02]:last-child {
  text-align: right;
}
.cart-prodict-type tr td[data-v-2a381d02] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}
.cart-pg-list-image[data-v-2a381d02] {
  width: 20%;
}
.cart-pg-list-prdt-info[data-v-2a381d02] {
  width: 75%;
}
.cart-list-other-act[data-v-2a381d02] {
  position: relative;
}
.cart-quantity-box[data-v-2a381d02] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 115px;
}
.checkout-form-wrap .yt-remove-arrow[data-v-2a381d02]::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow[data-v-2a381d02]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.checkout-form-wrap .yt-remove-arrow[type="number"][data-v-2a381d02] {
  -moz-appearance: textfield;
}
.cart-produt-list-wrap[data-v-2a381d02] {
  padding: 40px 40px 28px;
}
.yt-cart-price-lister[data-v-2a381d02] {
  padding: 40px 30px 56px;
  margin-bottom: 30px;
}
.checkout-form-wrap .cart-pg-inner-wrap[data-v-2a381d02] {
  padding: 40px 30px 30px;
}
.yt-cm-lt-col[data-v-2a381d02] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-2a381d02] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.cart-proceed-to-checkput.cart-btn-bill-pg[data-v-2a381d02] {
    display: none;
}
.cart-pg-list-image .cart-product-image img[data-v-2a381d02] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.cart-pg-list-image .cart-product-image[data-v-2a381d02] {
    width: 133px;
    height: 133px;
}
}
@media (min-width: 1280px) {
.empty-main-ttl[data-v-2a381d02] {
    display: none;
}
.cart-prodict-type th[data-v-2a381d02],
  .cart-prodict-type td[data-v-2a381d02] {
    padding: 3.5px 0;
    padding-right: 74px;
}
.cart-prodict-type th[data-v-2a381d02]:last-child,
  .cart-prodict-type td[data-v-2a381d02]:last-child {
    padding-right: 0;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-2a381d02] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-2a381d02] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-2a381d02] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-2a381d02] {
    padding-left: 20px;
}
.cart-product-title[data-v-2a381d02] {
    line-height: 1.67;
    max-width: 100%;
}
.cart-produt-list-wrap[data-v-2a381d02] {
    padding: 40px 40px 37px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.empty-cartn-ttl[data-v-2a381d02] {
    font-size: 24px;
    line-height: 1.17;
}
.empty-cart-text[data-v-2a381d02] {
    font-size: 14px;
    line-height: 1.14;
}
.empty-cart-btn[data-v-2a381d02] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.yt-empty-cart-wrap.cart-pg-inner-wrap[data-v-2a381d02] {
    margin-top: 10px !important;
}
.cart-page-main-title[data-v-2a381d02] {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
}
.cart-produt-list-wrap[data-v-2a381d02] {
    padding: 15px;
    margin-bottom: 10px;
}
.yt-cart-price-lister[data-v-2a381d02] {
    padding: 20px 15px;
}
.cart-product-title[data-v-2a381d02] {
    font-size: 16px;
    line-height: 1.13;
}
.cart-prodict-type th[data-v-2a381d02] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.cart-prodict-type tr td[data-v-2a381d02] {
    font-size: 16px;
    line-height: 1.31;
}
.cart-product-price[data-v-2a381d02] {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
}
.cart-prodict-amount thead th[data-v-2a381d02]:first-child {
    font-size: 18px;
    line-height: 1.11;
}
.cart-prodict-amount thead th[data-v-2a381d02]:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
}
.cart-product-amount-ttl[data-v-2a381d02] {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
}
.cart-product-amount-price[data-v-2a381d02] {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
}
.cart-prodict-amount tbody td[data-v-2a381d02],
  .cart-prodict-total-amount tbody td[data-v-2a381d02],
  .cart-prodict-sub-total-amount tbody td[data-v-2a381d02],
  .cart-prodict-sub-total-amount tbody td[data-v-2a381d02] {
    padding: 7.5px 0;
}
.cart-divider[data-v-2a381d02] {
    margin: 15px 0;
}
.cart-divider-internal[data-v-2a381d02]{
    margin: 15px 0;
}
.cart-proceed-to-checkput[data-v-2a381d02] {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.cart-coupon .form-control[data-v-2a381d02] {
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
.cart-coupon .error .cart-coupon-code-message[data-v-2a381d02] {
    font-size: 12px;
    line-height: 1.58;
}
.cart-coupon-change-btn[data-v-2a381d02] {
    font-size: 14px;
    line-height: 1.14;
}
.checkout-form-wrap .yt-chekout-radio .yt-currency-wrap.form-group[data-v-2a381d02] {
  
    padding-left: 0 !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cart-pg-inner-wrap
    .yt-chekout-radio
    .form-check-input:checked
    + label[data-v-2a381d02]:after {
    top: 4px !important;
}
.card-sub-text[data-v-2a381d02]{
    float: none;
}
.cart-pg-list-image .cart-product-image img[data-v-2a381d02] {
    width: 57px;
    height: 57px;
    object-fit: cover;
    object-position: top;
}
.cart-pg-list-image .cart-product-image[data-v-2a381d02] {
    width: 70px;
    height: 70 px;
}
.cart-checkout-wrap .cart-proceed-to-checkput[data-v-2a381d02] {
    margin-bottom:15px;
}
.yt-cm-row[data-v-2a381d02] {
    margin-left: -20px;
    margin-right: -20px;
}
.yt-cm-lt-col[data-v-2a381d02] {
    padding-right: 11px;
    padding-left: 11px;
}
.yt-cm-rt-col[data-v-2a381d02] {
    padding: 0;
}
.empty-cartn-ttl[data-v-2a381d02] {
    font-size: 17px;
    line-height: 1.12;
}
.empty-cart-text[data-v-2a381d02] {
    font-size: 15px;
    line-height: 1.2;
}
.empty-cart-btn[data-v-2a381d02] {
    font-size: 12px;
    line-height: 1.17;
}
.cart-page-main-title[data-v-2a381d02] {
    font-size: 18px;
    line-height: 1.11;
}
.cart-produt-list-wrap[data-v-2a381d02] {
    padding: 15px;
    margin-bottom: 10px;
}
.cart-product-title[data-v-2a381d02] {
    font-size: 14px;
    line-height: 1.36;
}
.cart-prodict-type th[data-v-2a381d02] {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
}
.cart-prodict-type tr td[data-v-2a381d02] {
    font-size: 14px;
    line-height: 1.5;
}
.cart-product-price[data-v-2a381d02] {
    font-size: 15px;
    line-height: 1.2;
}
.cart-product-delete svg[data-v-2a381d02] {
    font-size: 15px;
}
.cart-page-main-title[data-v-2a381d02] {
    margin-bottom: 10px !important;
}
.yt-cart-price-lister[data-v-2a381d02] {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
}
.cart-prodict-amount thead th[data-v-2a381d02]:first-child {
    font-size: 16px;
    line-height: 1.19;
}
.cart-prodict-amount thead th[data-v-2a381d02]:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
}
.cart-product-amount-ttl[data-v-2a381d02] {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
}
.cart-product-amount-price[data-v-2a381d02] {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
}
.cart-prodict-amount tbody td[data-v-2a381d02],
  .cart-prodict-total-amount tbody td[data-v-2a381d02],
  .cart-prodict-sub-total-amount tbody td[data-v-2a381d02],
  .cart-prodict-sub-total-amount tbody td[data-v-2a381d02] {
    padding: 8px 0;
}
.cart-divider[data-v-2a381d02] {
    margin: 3px 0 7px;
}
.cart-divider-internal[data-v-2a381d02]{
    margin: 3px 0 7px;
}
.cart-coupon .form-control[data-v-2a381d02] {
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
.cart-proceed-to-checkput[data-v-2a381d02] {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.checkout-form-wrap .cart-pg-inner-wrap[data-v-2a381d02] {
    padding: 20px;
    margin-bottom: 10px;
}
.cart-checkout-tile[data-v-2a381d02] {
    font-size: 15px;
    line-height: 1.2;
}
.cart-select-aar-btn[data-v-2a381d02] {
    font-size: 12px;
    line-height: 1.17;
}
.checkout-form-label[data-v-2a381d02] {
    font-size: 12px;
    line-height: 1.17;
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input[data-v-2a381d02] {
    font-size: 10px;
    line-height: 1.1;
}
.checkout-form-wrap .yt-chekout-radio .form-check-label[data-v-2a381d02] {
    font-size: 12px;
    line-height: 1.17;
}
.checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input[data-v-2a381d02] {
    margin-top: 0px;
}
  /* .checkout-form-wrap .yt-chekout-radio > div {
    width: 100%;
    margin-bottom: 10px;
  } */
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-2a381d02] {
    top: 6px;
}
}
@media (max-width: 575.98px) {
.empty-cart-text[data-v-2a381d02] {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
}
.empty-cart-btn[data-v-2a381d02] {
    width: 100%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-chekout-radio .checkout-form-label[data-v-2a381d02] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-inner-wrap .yt-items.item[data-v-2a381d02] {
    padding-top: 30px;
}
.empty-cart-btn[data-v-2a381d02] {
    font-size: 16px;
}
.cart-page-main-title[data-v-2a381d02] {
    font-size: 26px;
}
.cart-proceed-to-checkput[data-v-2a381d02] {
    font-size: 16px;
}
.cart-produt-list-wrap[data-v-2a381d02] {
    padding: 30px;
    margin-bottom: 20px !important;
}
.yt-cart-price-lister[data-v-2a381d02] {
    padding: 30px;
}
.cart-divider[data-v-2a381d02] {
    margin: 10px 0 !important;
}
.cart-divider-internal[data-v-2a381d02]{
    margin: 10px 0 !important;
}
form.yt-cart-disct-wrap[data-v-2a381d02] {
    padding: 10px 0 !important;
}
.cart-prodict-amount tbody tr:last-child td[data-v-2a381d02] {
    padding-bottom: 20px;
}
table.yt-sub-ttl-tbl-wrap.table tr:first-child td[data-v-2a381d02] {
    padding-top: 20px;
}
table.yt-sub-ttl-tbl-wrap.table[data-v-2a381d02] {
    margin: 0;
}
}
