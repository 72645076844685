:root {
  --color-primary: #f8d030;
  --color-white: #ffffff;
  --color-black: #3e454f;
  --color-dark-grey: #8b8f95;
  --color-dark-black: #242724;
  --color-green: #43b7a7;
  --color-red: red;
  --color-orange: #e65e52;
  --color-blue: #054995;
  --color-button-light: #81cabf;
  --color-button-dark: #29af9d;
}
#root br {
  display: none;
}

