.yt-3banner-title[data-v-5f9da0fd] {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.15;
  margin-top: 0;

  color: var(--color-dark-black);
}
.yt-3banner-title span[data-v-5f9da0fd] {
  color: var(--color-black);
}
.hover-me[data-v-5f9da0fd] {
  overflow: hidden;
  height: auto !important;
}
.hover-me img[data-v-5f9da0fd] {
  transition: transform 0.5s, -webkit-filter 1.5s ease-in-out;
  transition: transform 0.5s, filter 1.5s ease-in-out;
  transition: transform 0.5s, filter 1.5s ease-in-out, -webkit-filter 1.5s ease-in-out;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
.hover-me:hover img[data-v-5f9da0fd] {
  transform: scale(1.03);
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}
.hover-me:hover .yt-3banner-sp-btn[data-v-5f9da0fd] {
  background-color: #ffffff !important;
  color: #43b7a7 !important;
  background-image: none;
  border: 1px solid #43B7A6;
  border-radius: 6px;
}
.color-one[data-v-5f9da0fd]{
  color: var(--color-white) !important;
}
.yt-3banner-sub-title[data-v-5f9da0fd] {
  font-size: 22px;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-3banner-sp-btn[data-v-5f9da0fd] {
  opacity: 0.99;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: var(--color-white);
  width: 100%;
  max-width: 180px;
  text-align: center;
  background-image: linear-gradient(
    106deg,
    var(--color-button-light) 6%,
    var(--color-button-dark) 97%,
    var(--color-button-dark) 97%
  );
  transition: .5s ease;
}
.yt-3banner-sp-btn[data-v-5f9da0fd]:hover {
  background-image: linear-gradient( 
    106deg
    , var(--color-button-dark) 33%, var(--color-button-light) 97%, var(--color-button-light) 97% );
  color: var(--color-white);
}
.yt-3banner-divider[data-v-5f9da0fd],
.yt-3banner-big-divider[data-v-5f9da0fd] {
  height: 2px;
  background: #decdc5;
  width: 100%;
  display: block;
  max-width: 300px;
  margin: 15px 0 20px;
  left: -56px;
  position: relative;
}
.yt-3banner-wrapper[data-v-5f9da0fd] {
  position: absolute;
  top: 35px;
  left: 56px;
  max-width: 60%;
}
.yt-top-2[data-v-5f9da0fd] {
  top: auto;
  bottom: 50px;
}
.yt-top-2 .yt-3banner-title[data-v-5f9da0fd] {
  color: var(--color-black);
  font-size: 50px;
  font-weight: 500;
  line-height: 1.15;
}
.yt-top-2 .yt-3banner-sub-title[data-v-5f9da0fd] {
  color: var(--color-black);
  font-size: 22px;
  line-height: 1.14;
}
.yt-3banner-big-title[data-v-5f9da0fd] {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.12;
  color: var(--color-black);
}
.positionBanner[data-v-5f9da0fd]{
  left: 218px !important;
  bottom: 146px !important;
}
.yt-3banner-big-sub-title[data-v-5f9da0fd] {
  font-size: 22px;
  line-height: 1.14;
  color: #ffffff;
}
.yt-3banner-big-divider[data-v-5f9da0fd] {
  left: 0;
  right: -51px !important;
  margin-left: auto;
  width: 150%;
}
.yt-3banner-last-wrap[data-v-5f9da0fd] {
  position: absolute;
  bottom: 50px;
  right: 381px;
  left: 76px;
  top: 141px;
}
.yt-full-width[data-v-5f9da0fd] {
  max-width: 100%;
}
.yty-bg-3banner[data-v-5f9da0fd] {
  min-height: 250px;
  background-size: cover;
  background-position: 100%;
  position: relative;
}
.yt-first-bottom-banner[data-v-5f9da0fd] {
  min-height: 550px;
  background-size: cover;
  background-position: 100% 100%;
  position: relative;
}
.yt-cm-three-col[data-v-5f9da0fd] {
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
}
.yt-cm-three-col[data-v-5f9da0fd] {
  width: 50%;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-first-bottom-banner[data-v-5f9da0fd] {
    min-height: 330px;
}
.yty-bg-3banner[data-v-5f9da0fd] {
    min-height: 150px;
}
.yt-3banner-title[data-v-5f9da0fd] {
    font-size: 22px;
    line-height: 1.14;
}
.yt-3banner-sub-title[data-v-5f9da0fd] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-sp-btn[data-v-5f9da0fd] {
    font-size: 16px;
    line-height: 1.13;
    padding: 10px !important;
}
.yt-top-2 .yt-3banner-title[data-v-5f9da0fd] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-top-2 .yt-3banner-sub-title[data-v-5f9da0fd] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-title[data-v-5f9da0fd] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-sub-title[data-v-5f9da0fd] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-3banner-wrapper[data-v-5f9da0fd] {
    top: 19px;
}
.yt-3banner-last-wrap[data-v-5f9da0fd]{
    top: 19px;
    right: 213px;
    left: 50px;
}
.yt-3banner-divider.yt-first-divider[data-v-5f9da0fd] {
    margin: 12px 0;
}
.yt-3banner-divider.yt-second-divider[data-v-5f9da0fd] {
    margin: 20px 0 16px;
}
.yt-3banner-divider.yt-third-divider[data-v-5f9da0fd] {
    margin: 23px 0 16px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.promo-3-botom-info[data-v-5f9da0fd] {
    bottom: 30px;
}
.promo-3-img[data-v-5f9da0fd] {
    margin-bottom: 10px !important;
}
.yt-3banner-wrapper[data-v-5f9da0fd] {
    max-width: 100%;
}
.yt-3banner-last-wrap[data-v-5f9da0fd] {
    max-width: 100%;
    top: 100px !important;
}
.yt-3banner-last-wrap .yt-3banner-big-title[data-v-5f9da0fd]{
    font-size: 18px;
    line-height: 1.15;
}
.yt-3banner-last-wrap .yt-3banner-big-sub-title[data-v-5f9da0fd]{
    font-size: 14px;
    line-height: 1.15;
}
.yt-3banner-big-title[data-v-5f9da0fd] {
    margin-bottom: 10px;
}
.yty-bg-3banner[data-v-5f9da0fd] {
    background-position: center;
}
.yt-first-bottom-banner[data-v-5f9da0fd] {
    background-position: center;
}
.yt-cm-three-col[data-v-5f9da0fd] {
    width: 100%;
}
.yt-first-bottom-banner[data-v-5f9da0fd] {
    min-height: 322px;
}
.yt-cm-three-col[data-v-5f9da0fd] {
    min-height: 379px;
}
.yt-top-2 .yt-3banner-title[data-v-5f9da0fd] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-3banner-sp-btn[data-v-5f9da0fd] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px;
}
.yt-3promoo-main-wrapper[data-v-5f9da0fd] {
    margin: 0;
}
.yt-top-2[data-v-5f9da0fd]{
    top: 100px !important;
    left: 45px !important;
}
}
