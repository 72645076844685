.prodimage[data-v-45a7191f] {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  cursor: pointer;
}
.name[data-v-45a7191f] {
  margin-top: -30px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
.hp-collection-slider[data-v-45a7191f] {
  margin: 60px 0;
}
.yt-slider-left[data-v-45a7191f],
.yt-slider-right[data-v-45a7191f] {
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  color: #aaaaaa;
}
.item_box[data-v-45a7191f]{
  flex-direction: column;
    align-items: center;
}
.yt-slider-left[data-v-45a7191f]:hover,
.yt-slider-right[data-v-45a7191f]:hover{
  color: #636363;
}
.collection-card-slider > div > div[data-v-45a7191f] {
  margin: 0 !important;
}
.collection-card-slider .rec-carousel .rec-slider-container .rec-slider .rec-carousel-item .rec-swipable .rec-item-wrapper[data-v-45a7191f]{
  width: 180px !important;
  padding: 0px !important;
}
.yt-collection-item[data-v-45a7191f] {
  padding: 0;
  /* background: #fff;
  border: 1px solid #e2e2e2;
  width: 100%; */
  margin: 0 ;
  /* border-radius: 4px; */
  position: relative;
  height: auto;
  margin-bottom: 15px;
}
.yt-collection-item img[data-v-45a7191f]{
  transition: transform 0.5s, -webkit-filter 1.5s ease-in-out;
  transition: transform 0.5s, filter 1.5s ease-in-out;
  transition: transform 0.5s, filter 1.5s ease-in-out, -webkit-filter 1.5s ease-in-out;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
.yt-collection-item:hover img[data-v-45a7191f]{
  transform: scale(1.03);
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}
.yt-collection-item[data-v-45a7191f]:hover {
/* background-color: #30458c;
border-color: #30458c; */
transition: all .20s ease;
}
.yt-collection-title[data-v-45a7191f] {
  font-size: 16px;
  text-align: center;
  /* background-image: linear-gradient(
    to top,
    rgba(36, 39, 36, 0.92) 11%,
    rgba(36, 39, 36, 0) 100%
  ); */
  bottom: 15px;
  left: 15px;
  right: 15px;
  padding: 19px 0;
  line-height: 0.95;
  color: var(--color-black);
}
.yt-collection-component[data-v-45a7191f] {
  position: relative;
  border-radius: 10px;
  left: 30px;
}
.yt-collection-component .rec-item-wrapper[data-v-45a7191f] {
  outline: none !important;
  box-shadow: none;
}
.yt-component-more[data-v-45a7191f] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-comonent-top-title[data-v-45a7191f] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-produstslider-info[data-v-45a7191f] {
  padding-bottom: 30px;
}
@media (min-width: 1900px) {
.yt-collection-item[data-v-45a7191f] {
    margin: 0 15px;
}
}
@media (min-width: 1400px) {
.yt-component-more[data-v-45a7191f] {
    display: none;
}
}
@media (min-width: 1280px) {
.yt-collection-item img[data-v-45a7191f] {
    height: 80px;
    width: 80px;
    object-fit: cover;
    object-position: top;
    border-radius: 40px;
}
}
@media (min-width: 1281px) {
.yt-slider-left[data-v-45a7191f],
  .yt-slider-right[data-v-45a7191f] {
    top: 50%;
    transform: translateY(-50%);
}
.yt-slider-left[data-v-45a7191f] {
    left: -45px;
}
.yt-slider-right[data-v-45a7191f] {
    right: -45px;
}
}
@media (min-width: 1920px) {
.yt-collection-item img[data-v-45a7191f] {
    height: 270px;
    width: 320px;
    object-fit: cover;
    object-position: top;
    border-radius: 50px;
}
}
@media (max-width: 1280px) {
.yt-slider-left[data-v-45a7191f],
  .yt-slider-right[data-v-45a7191f] {
    top: -56px;
}
.yt-slider-left[data-v-45a7191f] {
    right: 43px;
}
.yt-slider-right[data-v-45a7191f] {
    right: 0;
}
.yt-comonent-link[data-v-45a7191f] {
    padding-right: 97px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-produstslider-info[data-v-45a7191f] {
    padding-bottom: 15px;
}
.yt-comonent-top-title[data-v-45a7191f] {
    font-size: 20px;
    line-height: 1.14;
}
.yt-component-more[data-v-45a7191f] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-slider-left[data-v-45a7191f],
  .yt-slider-right[data-v-45a7191f] {
    font-size: 21px;
    top: -40px;
}
.yt-slider-left[data-v-45a7191f] {
    right: 42px;
}
.yt-slider-right[data-v-45a7191f] {
    right: 20px;
}
.yt-collection-title[data-v-45a7191f] {
    font-size: 12px;
    line-height: 1;
    padding-top: 9px;
    padding-bottom: 13px;
    left: 0;
    right: 0;
    bottom: 0;
}
.yt-collection-item[data-v-45a7191f] {
    padding: 0 !important;
    border: none !important;
}
.yt-comonent-link[data-v-45a7191f] {
    padding-right: 55px;
}
.yt-collection-component[data-v-45a7191f] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0 !important;
}
section.hp-collection-slider[data-v-45a7191f] {
    margin: 20px 0 !important;
}
.yt-collection-item img[data-v-45a7191f] {
    height: 80px;
    width: 80px;
    object-fit: cover;
    object-position: top;
    border-radius: 40px;
}
}
/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-collection-item[data-v-45a7191f] {
    width: auto !important;
}
.hp-collection-slider[data-v-45a7191f] {
    position: relative;
}
.product.yt-collection-component.yt-collection-wrap[data-v-45a7191f] {
    padding-top: 52px !important;
    position: inherit;
}
.yt-produstslider-info[data-v-45a7191f] {
    position: absolute;
    top: 18px;
    left: 20px;
    right: 20px;
    z-index: 5;
}
.yt-slider-left[data-v-45a7191f],
  .yt-slider-right[data-v-45a7191f] {
    top: 17px;
    z-index: 10;
}
.yt-component-more[data-v-45a7191f] {
    border-bottom: none;
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
    margin: 0 !important;
}
.yt-comonent-top-title[data-v-45a7191f] {
    font-size: 17px;
    line-height: 1.12;
}
.yt-slider-left[data-v-45a7191f] {
    right: 41px;
}
.yt-comonent-link[data-v-45a7191f] {
    padding-right: 49px;
}
section.hp-collection-slider[data-v-45a7191f] {
    margin: 10px 0 !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-collection-title[data-v-45a7191f] {
    font-size: 16px;
}
.yt-comonent-top-title[data-v-45a7191f] {
    font-size: 26px;
}
}
