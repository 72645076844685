.pr-body-title[data-v-44f2f9c0] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-top: 22px;
}
#product-review-description[data-v-44f2f9c0] {
  min-height: 160px;
  border-radius: 10px;
  margin-top: 30px;
}
.pr-rate-submit-btn[data-v-44f2f9c0] {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: var(--color-black) !important;
  border: 2px solid #fff !important;
  box-shadow: none !important;
}
.yt-rating-wrapper.react-stars svg[data-v-44f2f9c0] {
  font-size: 29px;
}
.pr-rate-submit-btn[data-v-44f2f9c0] {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: .5s ease;
}
.pr-rate-submit-btn[data-v-44f2f9c0]:hover {
  border: 2px solid #fff !important;
  box-shadow: none !important;
  color: var(--color-black);
}
.pr-title-bar span[data-v-44f2f9c0] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.cm-product-star-rating span[data-v-44f2f9c0]:last-child {
  margin-right: 0;
}
.cm-product-star-rating span[data-v-44f2f9c0]:first-child {
  margin-left: 0;
}
.cm-product-star-rating span[data-v-44f2f9c0] {
  margin: 0 5px;
}
.cm-modal-body[data-v-44f2f9c0] {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.pr-bottom-bar[data-v-44f2f9c0] {
  margin-top: 5px;
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cm-modal-body[data-v-44f2f9c0] {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.yt-rating-wrapper[data-v-44f2f9c0] {
    margin: 0 auto;
}
.pr-body-title[data-v-44f2f9c0] {
    text-align: center;
}
}
