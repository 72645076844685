/*
* CSS
*/
/*
* Global CSS Classes
*/
.radius-10[data-v-3c2fa9ad] {
  border-radius: 10px;
}
.list-style-none[data-v-3c2fa9ad] {
  list-style: none;
}
.border-top-grey[data-v-3c2fa9ad] {
  border-top: 2px solid #dbdbdb;
}
.sp-breadcrumbs[data-v-3c2fa9ad] {
  margin-bottom: 46px;
}
.pageroute.sp-breadcrumbs span[data-v-3c2fa9ad] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.pageroute.sp-breadcrumbs .sp-current[data-v-3c2fa9ad] {
  color: var(--color-black);
  font-weight: 500;
}
.shippingInput[data-v-3c2fa9ad] {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #dbdbdb;
}
@media (min-width: 1900px) {
.sp-breadcrumbs[data-v-3c2fa9ad] {
    margin-bottom: 40px;
}
}
@media (min-width: 768px) {
.ml-md-0[data-v-3c2fa9ad] {
    margin-left: 0;
}
}
/*
* Single Product Left Section CSS
*/
.sp-image-slide img[data-v-3c2fa9ad] {
  border: 1px solid #dbdbdb;
}
.sp-description-title[data-v-3c2fa9ad] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
  font-weight: 500;
  margin-bottom: 27px;
}
.sp-description-text[data-v-3c2fa9ad] {
  font-size: 16px;
  color: var(--color-dark-grey);
  line-height: 2.13;
}
.yt-based-on[data-v-3c2fa9ad] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.sp-rating-content[data-v-3c2fa9ad] {
  font-size: 38px;
  line-height: 1.13;
  color: var(--color-black);
}
.sp-rating-view span[data-v-3c2fa9ad]:first-child {
  font-size: 20px;
  color: var(--color-black);
  line-height: 1.75;
}
span.sp-rating-area[data-v-3c2fa9ad] {
  min-width: 170px;
  height: 2px;
  background-color: #f6f8fa;
}
.sp-rating-wrap .sp-rating-inner-wrap[data-v-3c2fa9ad] {
  width: 50%;
}
.sp-give-review-btn[data-v-3c2fa9ad] {
  min-width: 200px;
  background-image: linear-gradient(
    101deg,
    #81cabf 6%,
    #29af9d 97%,
    #29af9d 97%
  );
  opacity: 0.99;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 1.11;
  transition: 0.5s ease;
}
.sp-give-review-btn[data-v-3c2fa9ad]:hover {
  background-image: none;
  background-color: #fff;
  border: 1px solid #29af9d;
  color: #29af9d;
}
.sp-rating-area[data-v-3c2fa9ad] {
  position: relative;
}
.sp-rating-area[data-v-3c2fa9ad]::after {
  content: "";
  height: 2px;
  position: absolute;
}
.sp-width-80[data-v-3c2fa9ad]::after {
  width: 80%;
  background-color: #43b7a7;
}
.sp-width-60[data-v-3c2fa9ad]::after {
  width: 60%;
  background-color: #43b7a7;
}
.sp-width-40[data-v-3c2fa9ad]::after {
  width: 40%;
  background-color: #054995;
}
.sp-width-20[data-v-3c2fa9ad]::after {
  width: 20%;
  background-color: #054995;
}
.sp-width-10[data-v-3c2fa9ad]::after {
  width: 10%;
  background-color: #e65e52;
}
.sp-rating-date[data-v-3c2fa9ad] {
  font-size: 12px;
  line-height: 1.17;
  color: var(--color-dark-grey);
}
.sp-user-name[data-v-3c2fa9ad] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.sp-usermessage[data-v-3c2fa9ad] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.sp-user-rated-meta img[data-v-3c2fa9ad] {
  max-width: 11px;
}
.yt-rating-single-prdt[data-v-3c2fa9ad],
.yt-rating-val-wrapper[data-v-3c2fa9ad] {
  pointer-events: none;
}
.yt-rating-val-wrapper[data-v-3c2fa9ad] {
  margin: 15px 0 30px;
}
/*
* Single Product Right Section CSS
*/
.added.sp-favorite-wrap[data-v-3c2fa9ad] {
  background-color: #d4ca6f;
}
.added.sp-favorite-wrap .sp-fav-icn[data-v-3c2fa9ad] {
  color: #fff;
}
.sp-fav-icn[data-v-3c2fa9ad] {
  width: 19px;
  height: 19px;
}
.product-title[data-v-3c2fa9ad] {
  color: var(--color-black);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.67;
}
.product-brand-name[data-v-3c2fa9ad] {
  color: var(--color-blue);
  font-size: 17px;
  font-weight: 500;
}
.product-shipping[data-v-3c2fa9ad] {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
}
.sp-favorite-wrap[data-v-3c2fa9ad] {
  box-shadow: 0 1px 4px 0 rgba(199, 214, 227, 0.5);
  border-radius: 100%;
}
.sp-price-tag-name[data-v-3c2fa9ad] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.sp-size-chart-tag-name[data-v-3c2fa9ad] {
  color: var(--color-dark-grey);
  line-height: 1.13;
  font-size: 16px;
}
.yt-prdt-size-chart[data-v-3c2fa9ad] {
  cursor: pointer;
}
.sp-price-tag-value[data-v-3c2fa9ad] {
  font-size: 28px;
  font-weight: 500;
  color: #43b7a7;
  line-height: 1.14;
  letter-spacing: 1.08px;
}
.sp-small-tag-value[data-v-3c2fa9ad] {
  font-size: 28px;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-green);
}
.sp-price-wrap[data-v-3c2fa9ad],
.sp-size-wrap[data-v-3c2fa9ad] {
  padding: 30px 0;
}
.sp-quantity-tag-name[data-v-3c2fa9ad] {
  font-size: 16px;
  color: var(--color-dark-grey);
  line-height: 1.13;
  letter-spacing: normal;
}
.sp-verify-icn-wrap[data-v-3c2fa9ad] {
  line-height: 14px;
  margin-right: 10px;
}
.sp-verify-icn-wrap img[data-v-3c2fa9ad] {
  max-height: 15px;
  width: auto;
}
.product-stock-message[data-v-3c2fa9ad] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-black);
}
.sp-small-tag-name[data-v-3c2fa9ad] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.sp-small-tag-name-pincode[data-v-3c2fa9ad] {
  font-size: 11px;
  line-height: 1.1;
  letter-spacing: normal;
  color: #43b7a7;
  font-weight: 500;
}
.sp-out-quantity-tag-name[data-v-3c2fa9ad] {
  font-size: 16px;
  color: var(--color-orange);
  line-height: 1.13;
  letter-spacing: normal;
}
.sp-ccolor-box[data-v-3c2fa9ad] {
  width: 33px;
  height: 33px;
  border-radius: 27px;
  position: relative;
  cursor: pointer;
  border: 1px solid #dee2e6;
}
.active.sp-ccolor-box[data-v-3c2fa9ad]::after {
  content: "";
  height: 11px;
  width: 11px;
  background-image: url("../images/verify-icn.png");
  position: absolute;
  background-size: cover;
  background-color: #fff;
  right: -5px;
  top: -5px;
  border-radius: 100%;
}
.sp-color-1[data-v-3c2fa9ad] {
  background-color: var(--color-black);
}
.sp-color-2[data-v-3c2fa9ad] {
  background-color: #f6f8fa;
}
.sp-color-3[data-v-3c2fa9ad] {
  background-color: var(--color-green);
}
.sp-color-4[data-v-3c2fa9ad] {
  background-color: var(--color-blue);
}
.sp-color-5[data-v-3c2fa9ad] {
  background-color: var(--color-dark-grey);
}
.sp-border-top-bottom[data-v-3c2fa9ad] {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.sp-size-details[data-v-3c2fa9ad] {
  min-width: 150px;
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  font-size: 17px;
  color: var(--color-black);
  line-height: 1.24;
  cursor: pointer;
}
.active.sp-size-details[data-v-3c2fa9ad] {
  background-image: linear-gradient(
    106deg,
    var(--color-button-light) 6%,
    var(--color-button-dark) 97%,
    var(--color-button-dark) 97%
  );
  color: #f6f8fa;
}
.active.sp-size-details[data-v-3c2fa9ad]:hover {
  background-image: linear-gradient(
    106deg,
    var(--color-button-dark) 33%,
    var(--color-button-light) 97%,
    var(--color-button-light) 97%
  );
}
.sp-addto-cart-btn[data-v-3c2fa9ad],
.sp-buy-now-btn[data-v-3c2fa9ad] {
  min-width: 125px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  opacity: 0.99;
  border-radius: 5px;
  height: 50px;
}
.shipping-cart-btn[data-v-3c2fa9ad] {
  width: 170px;
  height: 40px;
  background-color: #3e454f;
  color: var(--color-white);
  transition: 0.5s ease;
  font-size: 18px;
}
.shipping-cart-btn[data-v-3c2fa9ad]:hover {
  background-color: black;
  color: var(--color-white);
}
.sp-addto-cart-btn[data-v-3c2fa9ad] {
  background-image: linear-gradient(106deg, #004491 6%, #195aa4 97%);
  color: #ffffff;
  transition: 0.5s ease;
}
.sp-addto-cart-btn[data-v-3c2fa9ad]:hover {
  color: #004491;
  background-image: none;
  background-color: #fff;
  border: 1px solid #004491;
}
.add-to-cart-icon[data-v-3c2fa9ad] {
  font-size: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
}
.sp-buy-now-btn[data-v-3c2fa9ad] {
  background-image: linear-gradient(
    106deg,
    #81cabf 6%,
    #29af9d 97%,
    #29af9d 97%
  );
  color: #fff;
  transition: 0.5s ease;
}
.sp-buy-now-btn[data-v-3c2fa9ad]:hover {
  color: #29af9d;
  background-image: none;
  background-color: #fff;
  border: 1px solid #29af9d;
}
.yt-cm-lt-col[data-v-3c2fa9ad] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-3c2fa9ad] {
  padding-left: 7px;
}
.sp-inner-wrap[data-v-3c2fa9ad] {
  padding: 40px 30px 30px;
}
.sp-shipping-wrap[data-v-3c2fa9ad] {
  padding: 40px 30px 0px !important;
}
.sp-product-add-action-wrap[data-v-3c2fa9ad] {
  margin-top: 30px;
}
.sp-inner-content-wrap[data-v-3c2fa9ad] {
  padding: 30px 30px 35px 30px;
  margin-top: 20px;
}
@media (min-width: 1280px) {
.yt-tab-mob[data-v-3c2fa9ad] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-3c2fa9ad] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-3c2fa9ad] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-3c2fa9ad] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-3c2fa9ad] {
    padding-left: 20px;
}
}
@media (min-width: 769px) {
.sp-price-left-content[data-v-3c2fa9ad] {
    width: 100%;
}
.sp-price-right-content[data-v-3c2fa9ad] {
    width: 70%;
}
}
@media (min-width: 576px) {
.sp-addto-cart-btn[data-v-3c2fa9ad],
  .sp-buy-now-btn[data-v-3c2fa9ad] {
    min-width: 180px;
}
.shipping-cart-btn[data-v-3c2fa9ad] {
    min-width: 180px;
}
.sp-image-slide li.navigation-up[data-v-3c2fa9ad] {
    transform: rotate(180deg);
}
}
@media (min-width: 1280px) {
.sp-border-md-right[data-v-3c2fa9ad] {
    border-right: 2px solid #f6f8fa;
    padding-right: 15px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.sp-breadcrumbs[data-v-3c2fa9ad] {
    margin-bottom: 20px;
}
.sp-inner-wrap[data-v-3c2fa9ad],
  .sp-inner-content-wrap[data-v-3c2fa9ad] {
    padding: 20px 15px;
}
.sp-shipping-wrap[data-v-3c2fa9ad] {
    padding: 20px 15px !important;
}
.product-title[data-v-3c2fa9ad] {
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 7px;
}
.product-brand-name[data-v-3c2fa9ad] {
    font-size: 13px;
    margin-bottom: 5px;
}
.product-shipping[data-v-3c2fa9ad] {
    font-size: 15px;
    line-height: 1.15;
    margin-bottom: 7px;
}
.sp-price-wrap[data-v-3c2fa9ad],
  .sp-size-wrap[data-v-3c2fa9ad] {
    padding: 20px 0;
}
.sp-small-tag-name[data-v-3c2fa9ad] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.sp-small-tag-name-pincode[data-v-3c2fa9ad] {
    font-size: 11px;
    line-height: 1.1;
    letter-spacing: normal;
    font-weight: 500;
}
.sp-price-tag-value[data-v-3c2fa9ad] {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
}
.sp-quantity-tag-name[data-v-3c2fa9ad] {
    font-size: 12px;
    line-height: 2.33;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-3c2fa9ad] {
    font-size: 11px;
    line-height: 1.18;
}
.sp-addto-cart-btn[data-v-3c2fa9ad],
  .sp-buy-now-btn[data-v-3c2fa9ad] {
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    min-width: 150px;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
}
.sp-description-title[data-v-3c2fa9ad] {
    font-size: 16px;
    line-height: 1.13;
    margin-bottom: 9px;
}
.sp-description-text[data-v-3c2fa9ad] {
    font-size: 14px;
    line-height: 1.71;
}
.sp-rating-content[data-v-3c2fa9ad] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-rating-val-wrapper[data-v-3c2fa9ad] {
    margin: 8px 0;
}
.sp-rating-view span[data-v-3c2fa9ad]:first-child {
    font-size: 12px;
    line-height: 1.75;
}
.yt-rating-col[data-v-3c2fa9ad] {
    flex: 0 0 100%;
    max-width: 100%;
}
.sp-size-details[data-v-3c2fa9ad] {
    min-width: 150px;
    min-height: 40px;
    font-size: 12px;
}
.sp-inner-write-review[data-v-3c2fa9ad] {
    border-bottom: 2px solid #cae2fe;
    border-top: 2px solid #cae2fe;
    padding: 15px 0;
    margin-bottom: 5px;
}
.sp-user-name[data-v-3c2fa9ad] {
    font-size: 16px;
    line-height: 1.13;
}
.sp-rating-wrap[data-v-3c2fa9ad] {
    border: none;
    padding: 0 !important;
}
.sp-inner-write-review .sp-description-title[data-v-3c2fa9ad] {
    font-size: 14px;
    line-height: 1.14;
}
.sp-user-rated-meta ul[data-v-3c2fa9ad] {
    display: none !important;
}
.sp-user-info .yt-tab-mob ul li img[data-v-3c2fa9ad] {
    max-width: 12px;
}
.sp-rating-date[data-v-3c2fa9ad] {
    padding-top: 10px;
}
span.sp-rating-area[data-v-3c2fa9ad] {
    width: 80%;
}
.yt-rating-inner-row[data-v-3c2fa9ad] {
    flex-wrap: nowrap !important;
}
.yt-rating-inner-row .sp-rating-inner-wrap[data-v-3c2fa9ad]:last-child {
    width: 100%;
    padding-left: 15px;
}
.yt-rating-inner-row .sp-rating-inner-wrap[data-v-3c2fa9ad]:first-child {
    width: 255px !important;
}
.yt-rating-inner-row
    .sp-rating-inner-wrap:last-child
    ul
    li
    span[data-v-3c2fa9ad]:nth-child(2) {
    padding: 0 7px;
}
.yt-rating-inner-row .sp-rating-inner-wrap:last-child ul li span[data-v-3c2fa9ad]:first-child {
    width: 8px;
}
.sp-inner-write-review .sp-give-review-btn[data-v-3c2fa9ad] {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}
.sp-rating-wrap .sp-user-info[data-v-3c2fa9ad] {
    padding-left: 8px !important;
}
.sp-user-rated-meta[data-v-3c2fa9ad] {
    width: 70px;
}
#sp-size-data li[data-v-3c2fa9ad] {
    margin-right: 4px !important;
}
.sp-product-add-action-wrap[data-v-3c2fa9ad] {
    margin-top: 20px;
}
.sp-out-quantity-tag-name[data-v-3c2fa9ad] {
    font-size: 12px;
    line-height: 2.33;
}
.yt-product-img-inner-row .sp-inner-content-wrap[data-v-3c2fa9ad] {
    text-align: center;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-cm-lt-col[data-v-3c2fa9ad],
  .yt-cm-rt-col[data-v-3c2fa9ad],
  .yt-sp-description-col[data-v-3c2fa9ad] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-product-img-inner-row .sp-inner-content-wrap[data-v-3c2fa9ad] {
    text-align: center;
}
.yt-cm-lt-col[data-v-3c2fa9ad] {
    padding-right: 15px;
}
.yt-cm-rt-col[data-v-3c2fa9ad] {
    padding-left: 15px;
}
.right-inner-content-wrap[data-v-3c2fa9ad] {
    margin-top: 10px;
}
.right-inner-content-wrap[data-v-3c2fa9ad],
  .yt-lt-inner-bottom-content[data-v-3c2fa9ad] {
    margin-left: -20px;
    margin-right: -20px;
    overflow: hidden;
}
.right-inner-content-wrap .sp-inner-wrap[data-v-3c2fa9ad] {
    border-radius: 0 !important;
}
.right-inner-content-wrap .sp-size-wrap[data-v-3c2fa9ad] {
    /*display: none;*/
}
.right-inner-content-wrap .sp-price-wrap[data-v-3c2fa9ad] {
    border: none;
}
.product-title[data-v-3c2fa9ad] {
    max-width: 100%;
    width: 100%;
}
.product-brand-name[data-v-3c2fa9ad] {
    max-width: 100%;
    width: 100%;
}
.product-shipping[data-v-3c2fa9ad] {
    max-width: 100%;
    width: 100%;
}
.sp-price-wrap.yt-mb-space-border[data-v-3c2fa9ad] {
    padding-bottom: 0;
}
.yt-mb-space-border[data-v-3c2fa9ad] {
    position: relative;
}
.yt-mb-space-border[data-v-3c2fa9ad]::after {
    content: "";
    width: 150%;
    height: 4px;
    background: #f2f6f8;
    position: absolute;
    left: -20px;
    top: 0;
}
.yt-lt-inner-bottom-content .sp-inner-content-wrap[data-v-3c2fa9ad] {
    border-radius: 0;
}
.sp-description-text[data-v-3c2fa9ad] {
    font-size: 17px;
    color: var(--color-black);
    line-height: 1.24;
}
.sp-small-tag-name[data-v-3c2fa9ad],
  .sp-description-title[data-v-3c2fa9ad] {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
}
.sp-small-tag-name-pincode[data-v-3c2fa9ad] {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: normal;
    color: #43b7a7;
}
.sp-product-add-action-wrap[data-v-3c2fa9ad] {
    width: 100%;
}
.sp-product-add-action-wrap li[data-v-3c2fa9ad] {
    width: 50%;
}
.sp-description-text[data-v-3c2fa9ad] {
    max-height: 44px;
    overflow: hidden;
}
.sp-description-wrap.active-desc .sp-description-text[data-v-3c2fa9ad] {
    max-height: 100%;
}
.yt-read-more-des[data-v-3c2fa9ad] {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    color: #3bc490;
}
.sp-inner-write-review[data-v-3c2fa9ad] {
    border: none;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-3c2fa9ad]:last-child {
    border-bottom: none;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-3c2fa9ad]:first-child {
    border-top: solid 2px #f2f6f8;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-3c2fa9ad] {
    border-bottom: solid 2px #f2f6f8;
}
.yt-sp-title-wrapper[data-v-3c2fa9ad] {
    flex-wrap: wrap-reverse;
}
.sp-favorite-wrap[data-v-3c2fa9ad] {
    margin-left: auto;
    margin-bottom: 10px;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-3c2fa9ad] {
    display: none;
}
.sp-addto-cart-btn[data-v-3c2fa9ad],
  .sp-buy-now-btn[data-v-3c2fa9ad] {
    width: 100%;
}
.sp-out-quantity-tag-name[data-v-3c2fa9ad] {
    line-height: 1.75;
}
.yt-lt-inner-bottom-content .sp-inner-content-wrap[data-v-3c2fa9ad] {
    margin-top: 0;
}
}
@media (max-width: 575.98px) {
.sp-rating-view[data-v-3c2fa9ad] {
    /* max-width: 160px; */
}
.sp-rating-wrap .sp-rating-inner-wrap[data-v-3c2fa9ad] {
    width: 100%;
}
.sp-image-slide li.navigation-down[data-v-3c2fa9ad] {
    transform: rotate(270deg);
}
.sp-image-slide li.navigation-up[data-v-3c2fa9ad] {
    transform: rotate(90deg);
}
.sp-image-slide li[data-v-3c2fa9ad] {
    margin: 0 10px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.sp-inner-wrap[data-v-3c2fa9ad] {
    padding: 30px;
}
.sp-shipping-wrap[data-v-3c2fa9ad] {
    padding: 40px 30px 0px !important;
}
.product-title[data-v-3c2fa9ad] {
    line-height: 1.25;
}
.product-shipping[data-v-3c2fa9ad] {
    line-height: 1.25;
}
.sp-small-tag-name[data-v-3c2fa9ad] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.sp-small-tag-name-pincode[data-v-3c2fa9ad] {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: normal;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-3c2fa9ad] {
    font-size: 16px;
    line-height: 1.13;
}
.sp-price-wrap ul.list-style-none[data-v-3c2fa9ad] {
    margin-bottom: 0;
}
.sp-price-tag-value[data-v-3c2fa9ad] {
    font-size: 24px;
    line-height: 1.17;
    letter-spacing: 0.92px;
}
.sp-quantity-tag-name[data-v-3c2fa9ad] {
    font-size: 14px;
}
.sp-addto-cart-btn[data-v-3c2fa9ad],
  .sp-buy-now-btn[data-v-3c2fa9ad] {
    font-size: 16px;
}
.sp-give-review-btn[data-v-3c2fa9ad] {
    font-size: 16px;
}
.sp-product-add-action-wrap[data-v-3c2fa9ad] {
    margin-top: 20px;
}
.sp-out-quantity-tag-name[data-v-3c2fa9ad] {
    font-size: 14px;
    line-height: 2;
}
}
