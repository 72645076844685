.yt-main-contact-us-pg .contactform[data-v-ce81bee3] {
  padding: 25px 20px;
}
.contact-border[data-v-ce81bee3] {
  border: 1px solid #e6e6e6;
}
.yt-main-contact-us-pg .title[data-v-ce81bee3] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  margin-bottom: 30px;
  color: var(--color-black);
}
.yt-main-contact-us-pg .form[data-v-ce81bee3] {
  background-color: white;
  padding: 50px 40px;
}
.yt-main-contact-us-pg textarea[data-v-ce81bee3] {
  border-radius: 8px;
  padding: 12px;
  margin: 0px 20px;
  width: 100%;
  border-color: #c0bebe;
  font-size: 15px;
  height: 100px;
}
.yt-main-contact-us-pg textarea[data-v-ce81bee3]::-webkit-input-placeholder {
  color: #999;
}
.yt-main-contact-us-pg textarea[data-v-ce81bee3]:-ms-input-placeholder {
  color: #999;
}
.yt-main-contact-us-pg textarea[data-v-ce81bee3]::placeholder {
  color: #999;
}
.yt-main-contact-us-pg .send-button[data-v-ce81bee3] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
/* form starting stylings ------------------------------- */
.yt-main-contact-us-pg .group[data-v-ce81bee3] {
  position: relative;
  margin-bottom: 30px;
}
.yt-main-contact-us-pg input[data-v-ce81bee3] {
  font-size: 16px;
  padding: 10px 10px 16px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0bebe;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-main-contact-us-pg input[data-v-ce81bee3]:focus {
  outline: none;
}
/* LABEL ======================================= */
.yt-main-contact-us-pg label[data-v-ce81bee3] {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.yt-main-contact-us-pg input:focus ~ label[data-v-ce81bee3],
.yt-main-contact-us-pg input:valid ~ label[data-v-ce81bee3] {
  top: -20px;
  font-size: 14px;
  color: #5264ae;
}
/* BOTTOM BARS ================================= */
.yt-main-contact-us-pg .bar[data-v-ce81bee3] {
  position: relative;
  display: block;
  width: 100%;
}
.yt-main-contact-us-pg .bar[data-v-ce81bee3]:before,
.yt-main-contact-us-pg .bar[data-v-ce81bee3]:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--color-blue);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.yt-main-contact-us-pg .yt-main-contact-us-pg .bar[data-v-ce81bee3]:before {
  left: 50%;
}
.yt-main-contact-us-pg .bar[data-v-ce81bee3]:after {
  right: unset;
}
/* active state */
.yt-main-contact-us-pg input:focus ~ .bar[data-v-ce81bee3]:before,
.yt-main-contact-us-pg input:focus ~ .bar[data-v-ce81bee3]:after {
  width: 100%;
}
/* HIGHLIGHTER ================================== */
.yt-main-contact-us-pg .highlight[data-v-ce81bee3] {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.yt-main-contact-us-pg input:focus ~ .highlight[data-v-ce81bee3] {
  animation: inputHighlighter-data-v-ce81bee3 0.3s ease;
}
/* ANIMATIONS ================ */
@keyframes inputHighlighter-data-v-ce81bee3 {
from {
    background: var(--color-blue);
}
to {
    width: 0;
    background: transparent;
}
}
.yt-main-wrapper[data-v-ce81bee3] {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
}
.yt-main-contact-us-pg[data-v-ce81bee3] {
  margin-top: 60px;
  margin-bottom: 160px;
}
.yt-contact-send-btn[data-v-ce81bee3] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  padding: 15px 70px;
  border-radius: 6px;
  background-image: linear-gradient(
    106deg,
    #81cabf 6%,
    #29af9d 97%,
    #29af9d 97%
  );
  position: absolute;
  bottom: -82px;
  right: 0;
}
.yt-contact-send-btn[data-v-ce81bee3]:hover {
  border: 1px solid #29af9d;
  background-color: #ffffff;
  background-image: none;
  color: #29af9d;
}
@media (min-width: 1900px) {
.yt-main-contact-us-pg[data-v-ce81bee3] {
    margin-top: 80px;
}
}
@media (min-width: 768px) {
.yt-cm-mobile-bread[data-v-ce81bee3] {
    display: none;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-main-contact-us-pg[data-v-ce81bee3] {
    margin-top: 44px;
    margin-bottom: 110px;
}
.yt-main-contact-us-pg .title[data-v-ce81bee3] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 20px;
}
.yt-main-contact-us-pg .form[data-v-ce81bee3] {
    padding: 20px 15px;
}
.yt-main-contact-us-pg label[data-v-ce81bee3] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-contact-send-btn[data-v-ce81bee3] {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: 0.32px;
}
.yt-main-contact-us-pg input[data-v-ce81bee3] {
    padding: 10px 10px 16px 6px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.pageroute[data-v-ce81bee3] {
    color: gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.17;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-ce81bee3] {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
}
.yt-cm-mobile-bread[data-v-ce81bee3] {
    margin-top: 8px;
    margin-bottom: 10px;
}
.yt-main-contact-us-pg[data-v-ce81bee3] {
    margin-top: 0;
    margin-bottom: 20px;
}
.yt-main-contact-us-pg .title[data-v-ce81bee3] {
    font-size: 18px;
    line-height: 1.11;
    margin-bottom: 10px;
}
.yt-main-contact-us-pg .group[data-v-ce81bee3] {
    padding: 30px 20px;
}
.yt-main-contact-us-pg label[data-v-ce81bee3] {
    font-size: 13px;
    line-height: 1.15;
    padding-left: 20px;
}
.yt-main-contact-us-pg input[data-v-ce81bee3] {
    padding: 7px 10px 7px 0px;
}
.yt-contact-send-btn[data-v-ce81bee3] {
    position: static;
    position: initial;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    margin-top: 20px;
}
.yt-main-contact-us-pg .group[data-v-ce81bee3]:last-child {
    margin-bottom: 0;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-main-contact-us-pg .title[data-v-ce81bee3] {
    font-size: 26px;
}
}
