/*Order Detail css*/
.pp-order-product-ttl[data-v-581d556f] {
  font-size: 24px;
  font-weight: 500;
  color: var(--color-black);
  cursor: pointer;
  line-height: 1.17;
}
.order-ul-list-none[data-v-581d556f] {
  list-style: none;
}
.profile-pg-order-main-wrap[data-v-581d556f] {
  padding: 145px 0;
}
.radius-10[data-v-581d556f] {
  border-radius: 10px;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-581d556f] {
  padding: 30px;
}
.profile-pg-inner-wrap[data-v-581d556f] {
  border: 1px solid #e6e6e6;
}
.op-order-quantity[data-v-581d556f] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.op-order-product-price[data-v-581d556f] {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: #43b7a7;
}
.order-prodict-type th[data-v-581d556f],
.order-prodict-type td[data-v-581d556f] {
  padding: 3px;
}
.order-prodict-type th[data-v-581d556f]:first-child,
.order-prodict-type tr td[data-v-581d556f]:first-child {
  padding-left: 0 !important;
}
.order-prodict-type th[data-v-581d556f],
.order-prodict-type tr td[data-v-581d556f] {
  padding-left: 44px !important;
}
.order-prodict-type th[data-v-581d556f] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.order-prodict-type td[data-v-581d556f] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}
.od-product-img[data-v-581d556f] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  max-width: 133px;
}
.order-product-info[data-v-581d556f] {
  padding-left: 20px;
}
.yt-order-wrapper-box .order-product-quanity[data-v-581d556f] {
  position: absolute;
  right: 0;
  top: 30px;
  bottom: 15px;
}
.yt-order-wrapper-box[data-v-581d556f] {
  position: relative;
}
.order-number-wrap .order-tag[data-v-581d556f],
.order-date-wrap .order-tag[data-v-581d556f] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.order-number-wrap .order-tag-val[data-v-581d556f],
.order-date-wrap .order-tag-val[data-v-581d556f] {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #3e454f;
}
.order-bdr-between[data-v-581d556f] {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}
.order-write-review[data-v-581d556f] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-blue);
  text-decoration: underline;
  padding: 0 !important;
}
.order-cancel-btn[data-v-581d556f] {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
  position: absolute;
  bottom: -20px;
  right: 0;
}
.pp-od-no-ttl[data-v-581d556f] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-od-no-text.mb-0[data-v-581d556f] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-order-btn[data-v-581d556f] {
  opacity: 0.99;
  border-radius: 6px;
  background-image: linear-gradient(104deg, #81cabf 6%, #29af9d 97%, #29af9d 97%);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
  transition: .5s ease;
}
.pp-no-order-btn[data-v-581d556f]:hover{
  color: #29af9d;
  background-color: #fff;
  border: 1px solid #29af9d;
  background-image: none;
}
.profile-pg-inner-wrapper .yt-order-wrapper-box[data-v-581d556f]:last-child {
  padding-bottom: 0 !important;
}
.order-product-info[data-v-581d556f] {
  padding-left: 40px;
}
.profile-pg-order-main-wrap img[data-v-581d556f] {
  max-height: 217px;
}

/**/
.yt-order-wrapper-box .order-product-quanity .yt-qt-prc li[data-v-581d556f] {
  width: 100%;
  text-align: right;
}
.yt-order-wrapper-box .order-product-quanity .yt-qt-prc[data-v-581d556f] {
  height: 100%;
}
/**/
@media (min-width: 1900px) {
.pp-order-product-ttl[data-v-581d556f] {
    line-height: 1.67;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-581d556f] {
    padding: 40px;
}
.order-main-wrapper .profile-mo-dt-wrap[data-v-581d556f] {
    padding-left: 173px;
}
}
@media (max-width: 1899.98px) {
.pp-order-product-ttl[data-v-581d556f] {
    max-width: 300px;
}
}
@media (max-width: 1900px) and (min-width: 1366px) {
.order-number-wrap .order-tag[data-v-581d556f],
  .order-date-wrap .order-tag[data-v-581d556f],
  .order-number-wrap .order-tag-val[data-v-581d556f],
  .order-date-wrap .order-tag-val[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.36;
}
.order-write-review[data-v-581d556f] {
    font-size: 16px;
    line-height: 1.19;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.order-prodict-type th[data-v-581d556f] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-581d556f] {
    font-size: 16px;
    line-height: 1.31;
}
.od-product-img[data-v-581d556f] {
    max-width: 95px;
    padding: 11px !important;
}
.od-product-img img[data-v-581d556f] {
    width: 72px;
    height: 72px;
    object-fit: cover;
    object-position: top;
}
.order-product-info[data-v-581d556f] {
    padding-left: 20px;
}
.btn.od-cancel-btn[data-v-581d556f] {
    color: #e65e52;
    padding-right: 0 !important;
    padding-left: 0 !important;
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
}
.profile-pg-order-main-wrap[data-v-581d556f] {
    padding: 45px 0;
}
.profile-pg-order-main-wrap img[data-v-581d556f] {
    max-height: 180px;
    margin-bottom: 30px !important;
}
.pp-sa-order-wrap[data-v-581d556f] {
    margin-bottom: 30px !important;
}
.pp-no-order-btn[data-v-581d556f] {
    font-size: 16px;
    line-height: 1.13;
}
.profile-mo-dt-wrap[data-v-581d556f] {
    padding-left: 115px;
}
.yt-order-wrapper-box.yt-border-order[data-v-581d556f] {
    border-top: solid 1px #e6e6e6;
    padding-top: 30px !important;
    margin-top: 15px;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-581d556f] {
    margin-bottom: 20px !important;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-order-main-wrap img[data-v-581d556f] {
    margin-bottom: 40px !important;
}
.pp-sa-order-wrap[data-v-581d556f] {
    margin-bottom: 40px !important;
}
.pp-od-no-ttl[data-v-581d556f] {
    font-size: 24px;
    line-height: 1.17;
}
.pp-od-no-text[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-no-order-btn[data-v-581d556f] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.profile-pg-order-main-wrap[data-v-581d556f] {
    padding: 40px 0;
}
.od-product-img[data-v-581d556f] {
    max-width: 70px;
    align-self: flex-start;
}
.order-product-info[data-v-581d556f] {
    padding-left: 10px;
}
.pp-order-product-ttl[data-v-581d556f] {
    font-size: 16px;
    line-height: 1.13;
    max-width: 200px;
}
.yt-my-order-wrap .order-prodict-type th[data-v-581d556f] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.38px;
}
.yt-my-order-wrap .order-prodict-type td[data-v-581d556f] {
    font-size: 16px;
    line-height: 1.31;
}
.yt-my-order-wrap .op-order-quantity[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.36;
}
.yt-my-order-wrap .op-order-product-price[data-v-581d556f] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-order-wrapper-box .order-product-quanity[data-v-581d556f] {
    top: 15px;
}
.yt-my-order-wrap .order-number-wrap .order-tag[data-v-581d556f],
  .yt-my-order-wrap .order-date-wrap .order-tag[data-v-581d556f] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-581d556f],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-581d556f] {
    font-size: 12px;
    line-height: 1.58;
}
.order-write-review[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-581d556f] {
    padding: 18px 15px;
}
.profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord[data-v-581d556f] {
    padding-bottom: 40px;
}
.order-bdr-between[data-v-581d556f] {
    margin: 0 7.5px;
}
}
@media (max-width: 850px) and (min-width: 768px) {
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-581d556f],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-581d556f] {
    display: block;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-inner-wrap.profile-pg-inner-no-order[data-v-581d556f] {
    padding: 20px 9px;
}
.profile-pg-order-main-wrap[data-v-581d556f] {
    padding: 0;
}
.profile-pg-order-main-wrap img[data-v-581d556f] {
    max-height: 165px;
    margin-bottom: 16px !important;
}
.pp-od-no-ttl[data-v-581d556f] {
    font-size: 17px;
    line-height: 1.12;
}
.pp-od-no-text[data-v-581d556f] {
    font-size: 15px !important;
    line-height: 1.2 !important;
    max-width: 233px;
    margin: 0 auto;
}
.pp-no-order-btn[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.14;
    width: 100%;
    max-width: 100%;
}
.pp-sa-order-wrap[data-v-581d556f] {
    margin-bottom: 30px !important;
}
  /* yt-my-order-wrap */
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-581d556f] {
    padding: 15px;
    position: relative;
    margin: 67px 0;
}
.yt-my-order-wrap .od-product-img[data-v-581d556f] {
    max-width: 70px;
}
.yt-my-order-wrap .order-product-info[data-v-581d556f] {
    padding-left: 16px;
}
.yt-my-order-wrap .pp-order-product-ttl[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
}
.yt-my-order-wrap .order-prodict-type th[data-v-581d556f] {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
}
.od-cancel-btn[data-v-581d556f] {
    padding: 0;
}
.order-prodict-type th[data-v-581d556f],
  .order-prodict-type td[data-v-581d556f] {
    padding: 5px;
}
.yt-my-order-wrap .order-prodict-type td[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.5;
}
.yt-my-order-wrap .op-order-quantity[data-v-581d556f] {
    font-size: 10px;
    line-height: 1.9;
}
.yt-my-order-wrap .op-order-product-price[data-v-581d556f] {
    font-size: 15px;
    line-height: 1.2;
}
.profile-pg-inner-wrap .yt-my-order-tdet-wrap[data-v-581d556f] {
    
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
    align-items: flex-start !important;
}
.yt-my-order-wrap .order-number-wrap .order-tag[data-v-581d556f],
  .yt-my-order-wrap .order-date-wrap .order-tag[data-v-581d556f] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-581d556f],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-581d556f] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-bdr-between[data-v-581d556f] {
    display: none !important;
}
.yt-my-order-wrap .profile-mo-dt-wrap[data-v-581d556f] {
    max-width: 60%;
}
.yt-my-order-wrap .order-write-review[data-v-581d556f] {
    padding: 0 !important;
    font-size: 14px;
    line-height: 1.36;
}
.yt-my-order-wrap .order-cancel-btn[data-v-581d556f] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
    position: absolute;
    bottom: -25px;
    right: 0;
    padding: 0;
}
.yt-order-wrapper-box[data-v-581d556f] {
    padding-top: 0 !important;
}
.yt-order-wrapper-box .order-product-quanity[data-v-581d556f] {
    top: 0;
    bottom: 5px;
}
.profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord[data-v-581d556f] {
    padding-bottom: 40px;
}
}
