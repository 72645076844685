@import url("../fonts/GTWalsheimPro/stylesheet.css");

body {
  background-color: #f6f8fa !important;
  font: 15px "GT Walsheim Pro", Arial, Helvetica, sans-serif !important;
  padding: unset !important;
  color: unset !important;
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

input:disabled {
  background: #fff !important;
}

.modal-backdrop.show {
  opacity: 0.8;
  background-color: var(--color-black);
}
div:focus {
  outline: none !important;
}
#root {
  max-width: 1920px;
  margin: 0 auto;
}
.modal-content {
  border-radius: 10px;
}
.styles_scroll-to-top__2A70v {
  background-image: linear-gradient(
    106deg,
    var(--color-button-light) 6%,
    var(--color-button-dark) 97%,
    var(--color-button-dark) 97%
  );
  bottom: 115px;
}
.styles_scroll-to-top__2A70v:hover {
  background-image: linear-gradient(
    106deg,
    var(--color-button-dark) 33%,
    var(--color-button-light) 97%,
    var(--color-button-light) 97%
  );
}
.styles_scroll-to-top__2A70v:focus {
  outline: none;
}
.DIuaA {
  box-shadow: 0 0 1px 3px rgb(42 174 157) !important;
  background-color: #fff !important;
}
.kzPUbE {
  box-shadow: 0 0 1px 3px rgb(42 174 157) !important;
  background-color: #fff !important;
}

/*SCROLLBAR-START/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    106deg,
    var(--color-button-light) 6%,
    var(--color-button-dark) 97%,
    var(--color-button-dark) 97%
  );
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}input[type=number] {
  -moz-appearance: textfield;
}
/*SCROLLBAR-END/

/*
* Global Remove Number field arrow
*/
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-outer-spin-button,
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.yt-fp-form .yt-number-filed.form-group .form-control[type="number"] {
  -moz-appearance: textfield;
}

/*END*/
@media (min-width: 576px) {
  .cm-small-modal {
    max-width: 350px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-4 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-4 {
    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
  }
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1281px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 93.3%;
  }
}
@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 88.9%;
  }
}
@media (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
