/*
* YT Product Slider CSS
*/
.yt-product-fav[data-v-3e9630e8] {
  font-size: 24px;
  color: var(--color-dark-grey);
  cursor: pointer;
  margin-right: 12px;
}
.yt-product-off[data-v-3e9630e8] {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 60px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin: 0px 0px 15px 0px;
  background-image: linear-gradient(to bottom, #004491, #195aa4);
}
.yt-product-slider-cpnt[data-v-3e9630e8] {
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 50px;
  transition: all 0.2s ease;
  position: relative;
}
.yt-product-slider-cpnt[data-v-3e9630e8]:hover {
  box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 10%);
  /* transform: translateY(-10px); */
  transition: all 0.2s ease;
}
.yt-product-slider-cpnt:hover .yt-product-add-btn[data-v-3e9630e8] {
  background-color: #ffffff !important;
  color: #43b7a7 !important;
  background-image: none;
  border: 1px solid #43B7A6;
    border-radius: 6px;
}
.yt-product-slider-cpnt:hover .yt-product-off[data-v-3e9630e8] {
  background-image: linear-gradient(to bottom, #195aa4, #004491);
}
.yt-component-wrapper[data-v-3e9630e8] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-3e9630e8] {
  outline: none !important;
  box-shadow: none;
}
.yt-slider-left[data-v-3e9630e8],
.yt-slider-right[data-v-3e9630e8] {
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  color: #054995;
}
.yt-slider-left[data-v-3e9630e8]:hover,
.yt-slider-right[data-v-3e9630e8]:hover {
  color: #0f68cd;
}
.yt-item-slider[data-v-3e9630e8] {
  position: relative;
  width: 100%;
  margin: 0px 10px;
}
.yt-product-title[data-v-3e9630e8] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yt-product-brand[data-v-3e9630e8]{
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 500;
}
.yt-component-more[data-v-3e9630e8] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-comonent-top-title[data-v-3e9630e8] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-td-product-img[data-v-3e9630e8] {
  cursor: pointer;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
}
.yt-product-sale-price[data-v-3e9630e8] {
  color: var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-reg-price2[data-v-3e9630e8] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-decoration: line-through;
}
.yt-product-add-btn[data-v-3e9630e8] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: 0.36px;
  color: var(--color-white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-image: linear-gradient(to bottom, #29af9d 0%, #81cabf 79%);
  transition: 0.5s ease;
}
.yt-produstslider-info[data-v-3e9630e8] {
  padding-bottom: 30px;
}
.yt-top-offer-whl[data-v-3e9630e8] {
  min-height: 42px;
  position: absolute;
  width: 100%;
  left: 0;
}
.hp-product-slider[data-v-3e9630e8] {
  margin: 60px 0;
}
@media (min-width: 1900px) {
.yt-product-add-btn[data-v-3e9630e8] {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
}
.yt-item-slider[data-v-3e9630e8] {
    margin: 0 15px;
}
}
@media (min-width: 1281px) {
.yt-slider-left[data-v-3e9630e8],
  .yt-slider-right[data-v-3e9630e8] {
    top: 50%;
    transform: translateY(-50%);
}
.yt-slider-left[data-v-3e9630e8] {
    left: -45px;
}
.yt-slider-right[data-v-3e9630e8] {
    right: -45px;
}
}
@media (max-width: 1280px) {
.yt-slider-left[data-v-3e9630e8],
  .yt-slider-right[data-v-3e9630e8] {
    top: -56px;
}
.yt-slider-left[data-v-3e9630e8] {
    right: 43px;
}
.yt-slider-right[data-v-3e9630e8] {
    right: 0;
}
.yt-comonent-link[data-v-3e9630e8] {
    padding-right: 97px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-slider-right[data-v-3e9630e8] {
    right: 20px;
}
.yt-comonent-top-title[data-v-3e9630e8] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-component-more[data-v-3e9630e8] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-slider-left[data-v-3e9630e8],
  .yt-slider-right[data-v-3e9630e8] {
    font-size: 21px;
    top: -41px;
}
.yt-slider-left[data-v-3e9630e8] {
    right: 42px;
}
.yt-comonent-link[data-v-3e9630e8] {
    padding-right: 55px;
}
.yt-product-off[data-v-3e9630e8] {
    font-size: 18px;
    line-height: 1.17;
    min-width: 75px;
}
.yt-sglproduct-fav[data-v-3e9630e8] {
    font-size: 15px;
}
.yt-product-title[data-v-3e9630e8] {
    font-size: 14px;
    line-height: 0.71;
    margin-top: 15px !important;
}
.yt-product-brand[data-v-3e9630e8]{
    font-size: 14px;
    margin-top: 10px !important;
}
.yt-price-wrap[data-v-3e9630e8] {
    padding: 8px 0px 16px !important;
}
.yt-product-reg-price1[data-v-3e9630e8],
  .yt-product-reg-price2[data-v-3e9630e8] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-product-slider-cpnt[data-v-3e9630e8] {
    padding-bottom: 40px;
}
.yt-top-offer-whl[data-v-3e9630e8] {
    margin-top: 10px !important;
}
.yt-product-add-btn[data-v-3e9630e8] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.yt-produstslider-info[data-v-3e9630e8] {
    padding-bottom: 15px;
}
.yt-component-wrapper.yt-slider-component[data-v-3e9630e8] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
}
section.hp-product-slider[data-v-3e9630e8] {
    margin: 20px 0 !important;
}
.yt-td-product-img[data-v-3e9630e8] {
    width: 100%;
    height: 117px;
    max-width: 117px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-td-product-img[data-v-3e9630e8] {
    width: 100px;
    height: 100px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.yt-product-add-btn[data-v-3e9630e8] {
    font-size: 13px;
    line-height: 1.15;
}
section.hp-product-slider[data-v-3e9630e8] {
    position: relative;
}
.yt-component-wrapper.yt-slider-component[data-v-3e9630e8] {
    padding-top: 52px !important;
}
.yt-produstslider-info[data-v-3e9630e8] {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 18px;
    z-index: 5;
}
.yt-slider-left[data-v-3e9630e8],
  .yt-slider-right[data-v-3e9630e8] {
    top: 17px;
    z-index: 10;
}
.yt-component-more[data-v-3e9630e8] {
    border-bottom: none;
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
    margin: 0 !important;
}
.yt-comonent-top-title[data-v-3e9630e8] {
    font-size: 17px;
    line-height: 1.12;
}
.yt-slider-left[data-v-3e9630e8] {
    right: 41px;
}
.yt-comonent-link[data-v-3e9630e8] {
    padding-right: 49px;
}
.yt-product-title[data-v-3e9630e8] {
    font-size: 16px;
    line-height: 1.19;
}
.yt-product-brand[data-v-3e9630e8]{
    font-size: 14px;
}
.yt-product-sale-price[data-v-3e9630e8],
  .yt-product-reg-price[data-v-3e9630e8] {
    font-size: 15px;
    line-height: 1.2;
}
.yt-product-off[data-v-3e9630e8] {
    min-width: 35px;
}
.yt-item-slider[data-v-3e9630e8] {
    margin: 0 5px;
}
.yt-top-offer-whl[data-v-3e9630e8] {
    margin-top: 0 !important;
}
section.hp-product-slider[data-v-3e9630e8] {
    margin: 10px 0 !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-product-title[data-v-3e9630e8] {
    font-size: 18px;
    padding-top: 5px;
}
.yt-product-brand[data-v-3e9630e8]{
    font-size: 15px;
    padding-top: 3px;
}
.yt-product-slider-cpnt .price.yt-price-wrap[data-v-3e9630e8] {
    margin-bottom: 7px;
}
.yt-product-off[data-v-3e9630e8] {
    font-size: 18px;
    min-width: 80px;
}
.yt-comonent-top-title[data-v-3e9630e8] {
    font-size: 30px;
}
}
