/*
* Double CSS
*/
.yt-doc-inner[data-v-703ef656] {
  position: relative;
}
.yt-doc-tag[data-v-703ef656],
.yt-doc-daily[data-v-703ef656] {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.15;
  text-align: left;
  color: #ffffff;
}
.yt-doc-price-tagline[data-v-703ef656] {
  font-size: 16px;
  line-height: 1.63;
  text-align: left;
  color: #ffffff;
  padding-top: 22px;
}
.yt-doc-btn[data-v-703ef656] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  min-width: 180px;
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  margin-top: 35px;
}
.yt-doc-box[data-v-703ef656] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: 35px;
}
.yt-double-offer-component[data-v-703ef656] {
  margin: 42px 0 40px;
}
.yt-doc-daily-2[data-v-703ef656] {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.15;
  text-align: left;
  color: #fff;
}
.yt-doc-tag.yt-small-tag[data-v-703ef656] {
  font-size: 28px;
  line-height: 2;
  font-weight: 500;
}
/* .yt-doc-btn {
  position: absolute;
  bottom: 30px;
} */
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-doc-tag[data-v-703ef656],
  .yt-doc-daily[data-v-703ef656] {
    font-size: 24px;
    line-height: 1.46;
}
.yt-doc-daily-2[data-v-703ef656] {
    font-size: 40px;
}
.yt-doc-btn[data-v-703ef656] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
}
}
@media (min-width: 1900px) {
.yt-double-offer-component[data-v-703ef656] {
    margin: 31px 0 80px;
}
.yt-doc-tag[data-v-703ef656],
  .yt-doc-daily[data-v-703ef656] {
    font-size: 40px;
    line-height: 1.15;
}
.yt-doc-price-tagline[data-v-703ef656] {
    font-size: 22px;
    line-height: 1.73;
}
.yt-doc-tag.yt-small-tag[data-v-703ef656] {
    font-size: 40px;
    line-height: 2;
    font-weight: 500;
}
.yt-doc-daily-2[data-v-703ef656] {
    font-size: 60px;
}
.yt-doc-btn[data-v-703ef656] {
    margin-top: 57px;
}
}
@media (min-width: 1600px) {
}
@media (max-width: 1399.98px) {
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-doc-inner[data-v-703ef656] {
    margin-bottom: 0px !important;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-double-offer-component[data-v-703ef656] {
    margin: 10px 0 !important;
}
.yt-doc-tag[data-v-703ef656],
  .yt-doc-daily[data-v-703ef656] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-doc-price-tagline[data-v-703ef656] {
    padding-top: 1px !important;
    font-size: 14px;
    line-height: 0.93;
}
.yt-doc-btn[data-v-703ef656] {
    margin-top: 16px !important;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    width: 100%;
    max-width: 150px;
}
.yt-doc-box[data-v-703ef656] {
    padding: 18px 15px !important;
}
.yt-doc-tag.yt-small-tag[data-v-703ef656] {
    font-size: 14px !important;
    line-height: 2.29 !important;
}
.yt-doc-daily-2[data-v-703ef656] {
    font-size: 20px;
    margin-bottom: 4px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-cm-col[data-v-703ef656] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-doc-tag[data-v-703ef656],
  .yt-doc-daily[data-v-703ef656],
  .yt-doc-daily-2[data-v-703ef656] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-doc-price-tagline[data-v-703ef656] {
    padding-top: 5pt !important;
}
.yt-doc-price-tagline[data-v-703ef656],
  .yt-doc-tag.yt-small-tag[data-v-703ef656] {
    font-size: 14px;
    line-height: 0.93;
}
.yt-doc-btn[data-v-703ef656] {
    font-size: 14px;
    line-height: 1.14;
    padding-right: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px !important;
}
.yt-doc-box[data-v-703ef656] {
    top: 50% !important;
    transform: translateY(-50%);
}
}
